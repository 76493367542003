var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search_btn'),"single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.newTAsk()}}},[_vm._v(" "+_vm._s(_vm.$t('tasks.new_task_btn'))+" ")]),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('TaskEdit',{attrs:{"taskId":_vm.editTaskId,"linkMeetingId":_vm.editTaskMeetingId,"closeDialogFn":_vm.closeDialog}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.tasks,"search":_vm.search,"custom-sort":_vm.sortTasks,"sort-by":"dead_line","sort-desc":true},scopedSlots:_vm._u([{key:"item.create_time",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateToStr(item.create_time)))])]}},{key:"item.update_time",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateToStr(item.update_time)))])]}},{key:"item.dead_line",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateToStr(item.dead_line)))])]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})]}},{key:"item.is_cancelled",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.is_cancelled),callback:function ($$v) {_vm.$set(item, "is_cancelled", $$v)},expression:"item.is_cancelled"}})]}},{key:"item.creator_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.userName(item.creator_id)))])]}},{key:"item.updater_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.userName(item.updater_id)))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"blue darken-2"},on:{"click":function($event){_vm.editTaskId=item.id; _vm.editTaskMeetingId=item.meeting_link; _vm.dialog=true;}}},[_vm._v(" mdi-cards ")]),_c('span',[_vm._v(_vm._s(item.title))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }