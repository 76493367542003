





























































































































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { DATA } from '../_util/data'
import { REST } from '../_util/rest_call'
import DB from '../../../common/db_struct'
import Net from '../../../common/net_interface'

function getUsersOfType(is_amigo:boolean, is_kid:boolean, selectedId:number) {
    let users:any[] = (Array.from(store.getters.getUsers.values()) as Array<Net.Appuser>)
                    .filter((u:Net.Appuser) => ((u.is_amigo == is_amigo) && (u.is_kid == is_kid)));
    let selectedUser:Net.Appuser|null = null;
    if (selectedId > 0) {
        selectedUser = users.find((u:Net.Appuser) => u.id == selectedId)
    }
    return { users, selectedUser }
}

@Component({
  name: 'Pairing',
  data () {
        return {
            dialog: false,
            unsubscribe: null,
            search: '',
            pairs: new Array<DB.Amigoskids>(),
            editedPair: new DB.Amigoskids,
            amigos: new Array<Net.Appuser>(),
            kids: new Array<Net.Appuser>(),
            editedAmigoCombo: null,
            editedKidCombo: null,
            headers: [
                { text: this.$t('pairing.is_active'), value: 'is_active' },
                { text: this.$t('pairing.amigo'), align: 'start', sortable: true, value: 'amigo_id' },
                { text: this.$t('pairing.kid'), value: 'kid_id' },
                { text: this.$t('pairing.updater'), value: 'updater_id' },
                { text: this.$t('pairing.create_time'), value: 'create_time' },
                { text: this.$t('pairing.update_time'), value: 'update_time' },
            ],
        }
  },
  computed: {
    dateToStr() { 
      return DATA.dateToStr;
    },
    userName () { 
      return DATA.userName;
    }
  },
  methods: {
    isWSConnected() {
      return DATA.isWsConnected();
    },
    userFilter(item:Net.Appuser, queryText, itemText) {
        REST.logDebug("userFilter", item, queryText, itemText)
        const textOne =  DATA.userName(item.id).toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1;
    },
    getUserAvatarLink(item:Net.Appuser) {
        return DATA.getUserAvatarLink(item.id);
    },
    close () {
        this.$data.dialog = false;
    },
    togglePairing(item: DB.Amigoskids) {
        REST.logDebug("Save", this.$data.editedAmigoCombo,
                            this.$data.editedKidCombo,
                            this.$data.editedPair);
        // Edited/Moditifed Amigo/Kig pair
        REST.call("POST", "/users/pairs/" + item.amigo_id 
                        + "/" + item.kid_id + "/" + (item.is_active ? "0" : "1"),
                null,
                (r => { REST.logDebug("TogglePair:", r);
                    this.$data.dialog = false;
                    return r.detail;
                }),
                (async (r) => { 
                    REST.logError(" TogglePair:", r);
                }));
    },
    save () {
        REST.logDebug("Save", this.$data.editedAmigoCombo,
                            this.$data.editedKidCombo,
                            this.$data.editedPair);
        let item = this.$data.editedPair as DB.Amigoskids;
        item.amigo_id = (this.$data.editedAmigoCombo as Net.Appuser).id
        item.kid_id = (this.$data.editedKidCombo as Net.Appuser).id

        // Edited/Moditifed Amigo/Kig pair
        REST.call("POST", "/users/pairs/" + item.amigo_id 
                        + "/" + item.kid_id + "/" + (item.is_active ? "1" : "0"),
                null,
                (r => { REST.logDebug("EditPair:", r);
                    this.$data.dialog = false;
                    return r.detail;
                }),
                (async (r) => { 
                    REST.logError(" EditPair:", r);
                }));
    },
    newItem() {
        this.$data.editedPair = new DB.Amigoskids();
        this.$data.editedPair.is_active = true;
        {
            const { users, selectedUser } = getUsersOfType(true, false, -1);
            this.$data.amigos = users;
            this.$data.editedAmigoCombo = selectedUser;
        }
        {
            const { users, selectedUser } = getUsersOfType(false, true, -1);
            this.$data.kids = users;
            this.$data.editedKidCombo = selectedUser;
        }

        this.$data.dialog = true;
    },
    editItem(item: DB.Amigoskids) {
        this.$data.editedPair = new DB.Amigoskids(item);
        {
            const { users, selectedUser } = getUsersOfType(true, false, item.amigo_id);
            this.$data.amigos = users;
            this.$data.editedAmigoCombo = selectedUser;
        }
        {
            const { users, selectedUser } = getUsersOfType(false, true, item.kid_id);
            this.$data.kids = users;
            this.$data.editedKidCombo = selectedUser;
        }
        this.$data.dialog = true;
    },
  },
  created() {
    REST.logDebug("PAIRING: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      if ((mutation.type === 'mutatePairArray')
        || (mutation.type === 'setPair'))
      {
        //this.$data.groups = Array.from(store.getters.getNonAdminGroups.values());
        this.$data.pairs = Array.from(store.getters.getPairs.values());
        REST.logDebug("SUBSCRIBE: ", mutation.type, " Pairs:", this.$data.pairs);
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    store.dispatch('updatePairs');
    //this.$data.groups = Array.from(store.getters.getNonAdminGroups.values());
    this.$data.pairs = Array.from(store.getters.getPairs.values());
    REST.logDebug("PAIRING:beforeMount", this.$data.pairs);
  }
})
export default class Pairing extends Vue {}
  
