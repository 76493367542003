var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-file-input',{attrs:{"small-chips":"","multiple":"","filled":"","label":_vm.$t('task_document_to_upload'),"outlined":"","dense":""},model:{value:(_vm.uploadFilesArray),callback:function ($$v) {_vm.uploadFilesArray=$$v},expression:"uploadFilesArray"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{attrs:{"fab":"","color":"primary","small":"","disabled":!_vm.isUploadFileSelected()},on:{"click":_vm.onUploadFileClick}},[_c('v-icon',[_vm._v(" mdi-upload-multiple ")])],1)],1)],1),_c('v-data-table',{attrs:{"dense":"","headers":_vm.document_header,"sort-by":"description","custom-sort":_vm.sortDocuments,"items":_vm.replyDocuments},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [(!item.is_deleted)?_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"green darken-2"},on:{"click":function($event){return _vm.downloadDocument(item)}}},[_vm._v(" mdi-download ")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.description))])]}},{key:"item.file_size",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDocumentSize(item.file_size)))])]}},{key:"item.upload_time",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateToStr(item.upload_time)))])]}},{key:"item.is_deleted",fn:function(ref){
var item = ref.item;
return [(!item.is_deleted)?_c('v-icon',{staticClass:"mr-2",attrs:{"disabled":!_vm.canDeleteRestore(item),"medium":"","color":"red darken-2"},on:{"click":function($event){return _vm.deleteDocument(item)}}},[_vm._v(" mdi-delete-empty ")]):_c('v-icon',{staticClass:"mr-2",attrs:{"disabled":!_vm.canDeleteRestore(item),"medium":"","color":"green darken-2"},on:{"click":function($event){return _vm.restoreDocument(item)}}},[_vm._v(" mdi-delete-restore ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }