















































































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { REST } from '../_util/rest_call'
import { DATA, TodoSelectFn } from '../_util/data'
import { MessageStream } from '../_util/data'
import Net from '../../../common/net_interface'
import DB from '../../../common/db_struct';
import FilloutReplyShow from "./FilloutReplyShow.vue"

function getTaggingHtml(ids:string)
{
  let tagIds = ids.split(',');
  let rv = "";
  for (let id of tagIds) {
    rv += "<span style='background-color:orange;'>"
          + DATA.getGroupOrUserName(Number(id), false) + "</span> "
  }
  return rv;
}

@Component({
  name: 'ChatMessage',
  props: {
    messages: Array,
    chat: Object,
    isHistoryView: Boolean,
    openTaskReplyFn: { type:Function, default:null },
    openEventEditFn: { type:Function, default:null },
    openPreviewFn: { type:Function, default:null },
    allowStream: { type:Boolean, default:false }
  },
  components: {
    FilloutReplyShow
  },
  data() {
    return {
      showUserProfileDlg: false,
      userProfileJSON: "",
      userProfileFormId: 0,
    };
  },
  methods: {
    openTaskReply(chat:DB.Message) {
      if (this.$props.openTaskReplyFn) {
        this.$props.openTaskReplyFn(DATA.idFromChatContent(chat.content));
      };
    },
    openEventEdit(chat:DB.Message) {
      if (this.$props.openEventEditFn) {
        this.$props.openEventEditFn(DATA.idFromChatContent(chat.content));
      };
    },
    taskNotificationText(chat:DB.Message) {
      let taskId = DATA.idFromChatContent(chat.content);
      let rv = "";
      if (taskId > 0) {
        store.dispatch('updateTask', taskId);
        let task = store.getters.getTask(taskId) as Net.TaskAndAssignee;
        let tags = chat.content.split(" ");
        if (tags.length > 1) {
          rv = chat.content.substr(tags[0].length); // cut Task ID
          if (task) {
            rv = rv.replace(Net.MsgTag_Title_RE, "<strong>" + task.title + "</strong>");
            rv = rv.replace(Net.MsgTag_Date_RE, DATA.dateToDateTimeStrNoSec(task.dead_line));
            rv = rv.replace(Net.MsgTag_Description_RE, task.description);
          }
          rv = rv.replace(Net.MsgTag_I18n_RE, (str, p1, offset, s) => {
                                          // REST.logDebug("$T:", p1);
                                          return this.$t(p1).toString();
                                        });
        } else {
          if (task) {
            rv = this.$t('tasks.notification').toString() + "[" + DATA.dateToDateTimeStrNoSec(task.dead_line)+ "] " + task.title;
          } else {
            rv = this.$t('tasks.notification').toString() + " ID[" + chat.content + "]";
          }
        }
      }
      return rv;
    },
    meetingNotificationText(chat:DB.Message) {
      let meetingId = DATA.idFromChatContent(chat.content);
      let rv = "";
      if (meetingId > 0) {
        store.dispatch("updateMeeting", meetingId);
        let meeting = store.getters.getMeeting(meetingId) as Net.MeetingAndInvitees;
        let tags = chat.content.split(" ");
        
        if (tags.length > 1) {
          rv = chat.content.substr(tags[0].length); // cut Task ID
          if (meeting) {
            if (meeting.monthly_topic_id > 0) {
              store.dispatch("updateTopics");
              let topic = store.getters.getTopics.get(meeting.monthly_topic_id) as DB.Monthlytopic;
              if (topic) {
                rv = rv.replace(Net.MsgTag_Title_RE, "<strong>" + topic.title + "</strong>");
              }
            } else {
              rv = rv.replace(Net.MsgTag_Title_RE, "<strong>" + DATA.getMeetingTitle(meeting) + "</strong>");
            }
            rv = rv.replace(Net.MsgTag_Date_RE, DATA.dateToDateTimeStrNoSec(meeting.start_time));
            rv = rv.replace(Net.MsgTag_Description_RE, meeting.description);
          }
          rv = rv.replace(Net.MsgTag_I18n_RE, (str, p1, offset, s) => {
                                          // REST.logDebug("$T:", p1);
                                          return this.$t(p1).toString();
                                        });
        } else {
          if (meeting) {
            rv = this.$t('tasks.notification').toString() + "[" 
                + DATA.dateToDateTimeStrNoSec(meeting.start_time)
                + "] " + DATA.getMeetingTitle(meeting);
          } else {
            rv = this.$t('tasks.notification').toString() + " ID[" + chat.content + "]";
          }
        }
      }
      return rv;
    },
    getDockIdLink(id:number) {
        let doc = new DB.Document();
        doc.id = id;
        return REST.documentLink(doc.id);
    },
    getDocIdText(id:number) {
        return DATA.getDocName(id);
    },
    downloadImage(docId) {
      REST.downloadDocument(docId);
    },
    containsImage(chat:DB.Message) {
      return DATA.docIsImage(chat.attach_id);
    },
    containsPdf(chat:DB.Message) {
      return DATA.docIsPdf(chat.attach_id);
    },
    chatIsTaskNotification(chat:DB.Message) {
      return (chat.type_id == DATA.getNomenCodeId(store.getters.nomenclator.messageTypes, Net.MsgType_Task));
    },
    chatIsMeetingNotification(chat:DB.Message) {
      return (chat.type_id == DATA.getNomenCodeId(store.getters.nomenclator.messageTypes, Net.MsgType_Meeting));
    },
    chatIsHTML(chat:DB.Message) {
      return (chat.content.match(Net.RE_htmlTag) != null);
    },
    contentLines(chat:DB.Message) {
      let rv = new Array<string>();
      if (chat.type_id == DATA.getNomenCodeId(store.getters.nomenclator.messageTypes, Net.MsgType_Task))
      {
        let taskId = Number(chat.content);
        store.dispatch('updateTask', taskId);
        let task = store.getters.getTask(taskId) as Net.TaskAndAssignee;
        let taskDetail = chat.content;
        if (task) {
          taskDetail = "[" + DATA.dateToStr(task.dead_line)+ "] " + task.title;
        }
        rv.push("<button style='background-color:tomato;' onclick=\"openTodoPage\">" + this.$t('tasks.notification').toString() + taskDetail + "</button>");
      }
      else
      {
        let lines = chat.content.split("\n");
        lines.forEach(t => {
          t = t.replace(Net.MsgTag_Open_RE, '	&#60;');
          t = t.replace(Net.MsgTag_Close_RE, '	&#62;');
          t = t.replace(Net.RE_httpLink, (star, p1, p2) => {
                                        let linkStr = p1;
                                        if (!linkStr.startsWith("http")) {
                                          linkStr = "http://" + linkStr;
                                        }
                                        return "<a href='" + linkStr + "' target='_blank'>" + p1 + "</a>"
                                      });
          t = t.replace(Net.RE_boldText, '<strong>$1</strong>');
          let taggingMatch = Net.RE_tagging.exec(t);
          if (taggingMatch) {
            t = t.replace(taggingMatch[0], getTaggingHtml(taggingMatch[1]));
          }
          t = t.replace(Net.RE_smily, '$1');   // todo
          // dummy smilies: "&#x2660;&#x1F60C;&#x1F92E;&#x1F925;&#x1F1EC;&#x1F1E7;"
          rv.push(t)
        })
      }
      return rv;
    },
    openTodoPage() {
      TodoSelectFn();
    },
    isOwnMsg(msg:DB.Message) {
      return (msg.sender_id == REST.userId());
    },
    hasAnswers(msg:DB.Message) {
      return MessageStream.hasReply(msg);
    },
    isStream(msg:DB.Message) {
      return (!this.$props.isHistoryView && MessageStream.isStream(msg));
    },
    dateToTimeStr(dateStr) {
      return DATA.dateToTimeStr(dateStr)
    },
    replyClicked(msg:DB.Message) {
      this.$emit('replyClicked', msg)
    },
    userPresence(id:number) {
      return DATA.userPresent(id);
    },
    memberName(id:number, isGroup:boolean) {
        if (isGroup) {
            return DATA.groupName(id);
        } else {
            return DATA.userName(id);
        }
    },
    memberAvatarLink(id:number, isGroup:boolean) {
        if (isGroup) {
            return DATA.getUserAvatarLink(0);
        } else {
            return DATA.getUserAvatarLink(id);
        }
    },
    userPresenceGradient(id:number) {
      return DATA.userPresent(id) ? "" : "to bottom, rgba(255,255,255,0), rgba(255,255,255,1)";
    },
    showChatSenderProfile(id:number) {
      this.$data.userProfileJSON = DATA.getUserProfileJSON(id)
      this.$data.userProfileFormId = DATA.getUserProfileFormId(id)
      this.$data.showUserProfileDlg = true
    }
  },
  created() {
    // REST.logDebug("CHATMESSAGE: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type == 'setUserPresence')
      {
        let presence = mutation.payload as Net.User_Presence;
        if (presence.userPresence.findIndex(pair => 
                            (pair[0] == (this.$props.chat as DB.Message).sender_id)) != -1)
        {
          // Force update if Chat is for sender
          this.$forceUpdate();
        }
      }

      if (mutation.type === 'setDocument')
      {
        if ((this.$props.chat as DB.Message).attach_id > 0) {
          this.$forceUpdate();
        }
      }

      if (mutation.type === 'setTask' || mutation.type == 'mutateTaskArray')
      {
        let chat = this.$props.chat as DB.Message;
        if (chat.type_id == DATA.getNomenCodeId(store.getters.nomenclator.messageTypes, Net.MsgType_Task)) {
          this.$forceUpdate();
        }
      }

      if ((mutation.type === 'mutateTopicArray')
          || (mutation.type === 'setMeeting')
          || (mutation.type === 'mutateMeetingArray'))
      {
        let chat = this.$props.chat as DB.Message;
        if (chat.type_id == DATA.getNomenCodeId(store.getters.nomenclator.messageTypes, Net.MsgType_Meeting)) {
          this.$forceUpdate();
        }
      }
    });
  },
})
export default class ChatMessage extends Vue {}

