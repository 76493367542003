



































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { REST } from '../_util/rest_call'
import { DATA, KeyedInvitee, ChatRoomSelectFn } from '../_util/data'
import Net from '../../../common/net_interface'
import DB from '../../../common/db_struct';

@Component({
  name: 'FilloutReplyEdit',
  props: {
    formId: Number,
    value: String,
    modifying: Boolean
  },
  watch: {
    formId: function(newVal, oldVal) {
        this.$data.newOrEdit(newVal);
    },
    value: function(newVal, oldVal) {
        this.$data.filloutValues = DATA.getMapFromString(newVal);
    }
  },
  data () {
        return {
            unsubscribe: null,
            filloutItems: new Array<DB.Filloutitem>(),
            filloutValues: new Map<string, string>(),
            mandatoryKeys: new Array<string>()
        }
  },
  methods: {
    getItemValue(item:DB.Filloutitem) {
        let filloutValues = this.$data.filloutValues as Map<string, string>;
        if (filloutValues.has(item.name)) {
            return filloutValues.get(item.name);
        }
        return "";
    },
    getCheckboxValue(item:DB.Filloutitem, chkVal:string) {
        let filloutValues = this.$data.filloutValues as Map<string, string>;
        let strVals = filloutValues.get(item.name);
        if (strVals) {
            let values = strVals.split("\t");
            return (values.findIndex(v => v==chkVal) != -1);
        }
        return false;
    },
    setCheckboxValue(item:DB.Filloutitem, chkVal:string, event:boolean) {
        // REST.logDebug("SET [", item.name, " / " + chkVal + "] ", event);
        let filloutValues = this.$data.filloutValues as Map<string, string>;
        let values = new Array<string>();
        let strVals = filloutValues.get(item.name);
        if (strVals) {
            values = strVals.split("\t");
        }
        if (event) {
            if (values.findIndex(v => v==chkVal) == -1) {
                values.push(chkVal);
            }
        } else {
            values = values.filter(v => v!=chkVal);
        }
        let strValue = "";
        values.forEach(v => {
            if (strValue.length > 0) {
                strValue += "\t";
            }
            strValue += v;
        })
        filloutValues.set(item.name.toString(), strValue.toString());
        // REST.logDebug("JSON:", filloutValues, JSON.stringify(Array.from(filloutValues.entries())));
        const completed = this.$data.getCompleted(filloutValues)
        this.$emit('dataComplete', completed)
        this.$emit('change', JSON.stringify(Array.from(filloutValues.entries())));
    },
    setItemValue(item:DB.Filloutitem, event:any) {
        // REST.logDebug("SET [", item.name, "] ", event);
        let filloutValues = this.$data.filloutValues as Map<string, string>;
        filloutValues.set(item.name.toString(), event.toString());
        // REST.logDebug("JSON:", filloutValues, JSON.stringify(Array.from(filloutValues.entries())));
        const completed = this.$data.getCompleted(filloutValues)
        this.$emit('dataComplete', completed)
        this.$emit('change', JSON.stringify(Array.from(filloutValues.entries())));
    },
    getItemOptionsAsArray(item:DB.Filloutitem) {
        return item.options.split("\n");
    },
  },
  created() {
    REST.logDebug("FilloutReplyEdit: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'mutateQuestionerArray')
      {
        (mutation.payload as Array<Net.FillOutForm>).forEach(f => {
            if (f.id == this.$props.formId) {
                REST.logDebug("SUBSCRIBE: ", mutation.type, " FilloutReplyEdit:", f);
                this.$data.newOrEdit(this.$props.formId);
            }
        });
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    store.dispatch('updateQuestioners');

    this.$data.newOrEdit = (formId:number) => {
        this.$data.filloutItems = new Array<DB.Filloutitem>();
        if (formId > 0) {
            let questioner = store.getters.getQuestioner(formId) as Net.FillOutForm;
            if (questioner) {
                let items = questioner.items;
                items.sort((a, b) => a.item_index - b.item_index);
                this.$data.filloutItems = items;
            }
            this.$data.mandatoryKeys = this.$data.filloutItems.filter(item => item.mandatory).map(item => item.name)
        }
    }
    this.$data.getCompleted = (filloutValues:Map<string, string>): boolean => {
        const rv = this.$data.mandatoryKeys.reduce((acc:boolean, itemname:string) => 
            {return acc && filloutValues.has(itemname) && filloutValues.get(itemname)
            }, true)
        return rv;        
    }
    this.$data.newOrEdit(this.$props.formId);
    this.$data.filloutValues = DATA.getMapFromString(this.$props.value);
    this.$emit('dataComplete', this.$data.getCompleted(this.$data.filloutValues))
    REST.logDebug("FilloutReplyEdit:beforeMount", this.$props.formId);
  }
})
export default class FilloutReplyEdit extends Vue {}
