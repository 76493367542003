













































import { Component, Vue } from 'vue-property-decorator';
import { DATA, KeyedInvitee, ChatRoomView } from '../_util/data'
import DB from '../../../common/db_struct';
import store from '../store'
import Net from '../../../common/net_interface'
import { REST } from '../_util/rest_call'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Invitees from './Invitees.vue'

function getRoomUserMebersId(roomId:number, isHistoryView:boolean)
{
  let rv = new Array<DB.Meetinginvitee>();
  let tmpInvitee = new DB.Meetinginvitee();
  tmpInvitee.id = 0
  tmpInvitee.meeting_id = 0
  tmpInvitee.member_id = 0
  tmpInvitee.is_member_group = false;
  tmpInvitee.is_deleted = false;

  let room = store.getters.getRoom(roomId) as Net.ChatRoomAndMembers;
  if ((room!=null) && (room.members.length > 0)) {
    let sysRoomName = Net.SYSTEM_ROOM_PREFIX + REST.userId();
    if (room.unique_id == sysRoomName) {
      // No tagging for System chatroom
      return rv;
    }

    if (room.unique_id == Net.AmigoSysRoom) {
      if (!isHistoryView) {
        let users = store.getters.getUsers as Map<number, Net.Appuser>;
        users.forEach(v => {
          if (v.is_amigo) {
            if (rv.findIndex(ui => ui.id == v.id) == -1) {
              tmpInvitee.id += 1
              tmpInvitee.member_id = v.id;
              rv.push(new DB.Meetinginvitee(tmpInvitee));
            }
          }
        })
      }
    } else if (room.unique_id === Net.KidSysRoom) {
      if (!isHistoryView) {
        let users = store.getters.getUsers as Map<number, Net.Appuser>;
        users.forEach(v => {
          if (v.is_kid) {
            if (rv.findIndex(ui => ui.id == v.id) == -1) {
              tmpInvitee.id += 1
              tmpInvitee.member_id = v.id;
              rv.push(new DB.Meetinginvitee(tmpInvitee));
            }
          }
        })
      }
    } else {
      // collect all members
      let roomView = new ChatRoomView(room);
      room.members.forEach(v => {
        if (v.is_member_group) {
          let group = DATA.getGroup(v.member_id) as Net.UserGroupAndMembers;
          if (group) {
            group.members.filter(m => roomView.showUser(DATA.getUser(m.user_id) as Net.Appuser)).forEach(m => {
              if (rv.findIndex(ui => ui.id == m.user_id) == -1) {
                tmpInvitee.id += 1
                tmpInvitee.member_id = m.user_id;
                rv.push(new DB.Meetinginvitee(tmpInvitee));
              }
            })
          }
        } else if (roomView.showUser(DATA.getUser(v.member_id) as Net.Appuser)) {
          if (rv.findIndex(ui => ui.id == v.member_id) == -1) {
            tmpInvitee.id += 1
            tmpInvitee.member_id = v.member_id;
            rv.push(new DB.Meetinginvitee(tmpInvitee));
          }
        }
      })
    }
  } else {
    REST.logDebug("updateRoom:");
    store.dispatch('updateRoom', roomId);
  }

  return rv;
}

@Component({
  name: 'ChatEdit',
  components: {
    Invitees
  },
  props: {
    sendChat: Function,
    roomId: Number,
    isHistoryView: Boolean
  },
  data() {
    return {  isSelecting: false,
              selectedFile: null,
              message_text: "",
              enableTagging: true,
              taggigDlg: false,
              tagged: [],
              searchTagged: '',
              chatUsers: new Array<DB.Meetinginvitee>(),
              headers: ([ { text: this.$t('invitee_name'), align: 'start', sortable: true, value: 'name' }
                        ]),
              unsubscribe: null,
              quillConfig: {
                    modules: {
                      toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],

                        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                         // text direction
                        [{ 'align': [] }],

                        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'font': [] }],
                        ['image', 'code-block']
                      ],
                    }
              }
            }
  },
  watch: {
    roomId: function(newVal, oldVal) {
      // chat room changed
      this.$data.isSelecting = false;
      this.$data.selectedFile = null;
      this.$data.message_text = "";

      this.$data.taggigDlg = false;
      this.$data.tagged = [];
      this.$data.searchTagged = "";
      this.$data.chatUsers = getRoomUserMebersId(newVal, this.$props.isHistoryView);
      this.$data.enableTagging = this.$data.chatUsers.length > 0;
    }
  },
  methods: {
    isWSConnected() {
      return DATA.isWsConnected();
    },
    notNewsOrHistory() {
      // room is MESSAGE_BOARD and user is admin and not historyView
      let room = store.getters.getRoom(this.$props.roomId) as Net.ChatRoomAndMembers;
      return ((this.$props.isHistoryView)
              || (room.unique_id != Net.NewsRoom))
    },
    richTextEdit() {
      return DATA.adminInNewsRoom(this.$props.roomId, this.$props.isHistoryView);
    },
    sendMessage() {
      REST.logDebug(this.$data.message_text);
      let msg = this.$data.message_text.trim();
      if (this.$data.tagged.length > 0) {
        let tagging = "";
        (this.$data.tagged as Array<KeyedInvitee>).forEach((t:KeyedInvitee) => {
          if (tagging.length >0) {
            tagging += ","
          }
          tagging += t.data.member_id.toString()
        });
        msg = Net.TAGGING_PREFIX + tagging + Net.TAGGING_SUFFIX + msg;
      }
      this.$data.message_text = this.$props.sendChat(msg, this.$data.selectedFile);
      if (DATA.adminInNewsRoom(this.$props.roomId, this.$props.isHistoryView)) {
        let quill = ((this.$refs.msgText as any).$data.editor as any);
        REST.logDebug(quill)
        quill.setText(this.$data.message_text);
      }
      this.$data.selectedFile=null;
      this.$data.tagged=[]
    },
    memberName (userId) {
      return DATA.userName(userId);
    },
    memberType (userId) {
      return this.$t(DATA.getUserType(userId));
    },
    onUploadClick() {
      this.$data.isSelecting = true
      window.addEventListener('focus',
                              () => {
                                   this.$data.isSelecting = false
                              }, { once: true });
      (this.$refs.uploader as HTMLInputElement).value = '';
      (this.$refs.uploader as HTMLInputElement).click();
    },
    selectTagged() {
      this.$data.taggigDlg = true;
    },
    inputAction(e) {
        // Implement tagging support
        let curTxt = this.$data.message_text;
        //const msgText = (this.$refs.msgText as Vue).$el.querySelector('textarea') as HTMLTextAreaElement;
        const msgText = (this.$refs.msgText as Vue).$refs.input as HTMLTextAreaElement;
        let pos = msgText.selectionStart
        REST.logDebug("INPUT", pos, msgText)
        this.$nextTick().then(() => {
            // msgText.selectionStart = pos + insertText.length
        })
    },
    onFileChanged(e) {
      REST.logDebug("onFileChanged", e.target.files)
      this.$data.selectedFile = e.target.files[0]
      // do something
    },
    isFileSelected() {
      return this.$data.selectedFile != null;
    },
    sortTagable(items:Array<number>, sortBy: string[], sortDesc: boolean[]) {
      if ((sortBy.length == 0) || (sortDesc.length == 0)) {
        return items;
      }
      let index = sortBy[0];
      let isDesc = sortDesc[0];
      items.sort((a, b) => {
        if (index == "name") {
          return DATA.strCmp(DATA.userName(a), DATA.userName(b), isDesc);
        } else if (index == "type") {
          return DATA.strCmp(this.$t(DATA.getUserType(a)).toString(),
                            this.$t(DATA.getUserType(b)).toString(), isDesc);
        }
        REST.logDebug("sortTagable Unknown index:", index);
        return 1;
      })
      return items;
    },
    filterTaggables(value: any, search: string | null, item:DB.Meetinginvitee) {
      if (search == null)
        return false;
      let un = DATA.userName(Number(item.member_id));
      return un.toUpperCase().includes(search.toUpperCase()) 
    }
  },
  created() {
    REST.logDebug("CHAT-EDIT: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      if ((mutation.type === 'mutateGroupArray') 
          || (mutation.type === 'mutateRoomArray'))
      {
        this.$data.chatUsers = getRoomUserMebersId(this.$props.roomId, this.$props.isHistoryView);
        this.$data.enableTagging = this.$data.chatUsers.length > 0;
        REST.logDebug("SUBSCRIBE: ", mutation.type, " chatUsers:", this.$data.chatUsers.length);
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    this.$data.chatUsers = getRoomUserMebersId(this.$props.roomId, this.$props.isHistoryView);
    this.$data.enableTagging = this.$data.chatUsers.length > 0;
  },
})
export default class ChatEdit extends Vue {}

