











































































































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { REST } from '../_util/rest_call'
import { DATA, ChatRoomView } from '../_util/data'
import { MessageStream } from '../_util/data'
import Net from '../../../common/net_interface'
import DB from '../../../common/db_struct'
import ChatMessage from "./ChatMessage.vue"
import ChatEdit from "./ChatEdit.vue"
import pdf from 'vue-pdf'
import TaskReplyEdit from "./TaskReplyEdit.vue"
import MeetingEdit from "./MeetingEdit.vue"

function sendChatMessage(message:DB.Message)
{
  REST.logDebug("CHAT message", message);
  REST.call("POST", "/messages", message,
              (r => {
                  REST.logDebug("CreateMessage REPLY:", r);
                  return r.detail;
              }),
              (async (r) => { 
                REST.logError(" CreateMessage:", r);
              }));
}

@Component({
  name: 'Chat',
  props: {
    room_id: Number,
  },
  components: {
    ChatMessage,
    TaskReplyEdit,
    MeetingEdit,
    ChatEdit,
    pdf
  },
  data() {
    return {  historyDlg: false,
              taskReplyDlg: false,
              eventDlg: false,
              previewDlg: false,
              title: "",
              editTaskId: 0,
              editMeetingId: 0,
              previewDocId: 0,
              pdfPageCount: 0,
              pdfPageIdx: 1,
              scrollToBottom: false,
              scrollToBottomHistory: false,
              messages: new Array<DB.Message>(),
              historyRoot: DB.Message || null,
              historyMsgs: new Array<DB.Message>(),
              unsubscribe: null }
  },
  methods: {
    getPdfUrl(id:number) {
      return REST.documentLink(id) + '.pdf';
    },
    pdfLoadError: function(err) {
      REST.logError("PDF [", REST.documentLink(this.$data.previewDocId), "]error: ", err);
    },
    editTaskReply(tId:number) {
      this.$data.editTaskId = tId;
      this.$data.taskReplyDlg = true;
    },
    closeTaskReplyDlg() {
      this.$data.taskReplyDlg = false;
    },
    editEvent(mId:number) {
      this.$data.editMeetingId = mId;
      this.$data.eventDlg = true;
    },
    openPreview(docId:number) {
      this.$data.previewDocId = docId;
      this.$data.pdfPageCount = 0;
      this.$data.pdfPageIdx = 1;
      this.$data.previewDlg = true;
    },
    closeEventDialod() {
      this.$data.eventDlg = false;
    },
    getDockIdLink(id:number) {
      let doc = new DB.Document();
      doc.id = id;
      return REST.documentLink(doc.id);
    },
    downloadBtnText(id:number) {
      return this.$t("download_btn_prefix").toString() + DATA.getDocName(id);
    },
    downloadDocument(docId) {
      REST.downloadDocument(docId);
    },
    containsImage(docId:number) {
      return DATA.docIsImage(docId);
    },
    containsPdf(docId:number) {
      return DATA.docIsPdf(docId);
    },
    onHistoryScroll(event) {
      if (event.target.scrollTop == 0) {
        // if more messages available then load them from the server
        let room = store.getters.getRoom(this.$props.room_id) as Net.ChatRoomAndMembers;
        if (!room.allMsgReceived) {
          REST.logDebug("SCROLL-READ History from: ", room.firstMessageId);
          store.dispatch('updateRoom', this.$props.room_id);
        }
      }
      
      this.$data.scrollToBottomHistory = (event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight);
    },
    onChatScroll(event) {
      if (event.target.scrollTop == 0) {
        // if more messages available then load them from the server
        let room = store.getters.getRoom(this.$props.room_id) as Net.ChatRoomAndMembers;
        if (!room.allMsgReceived) {
          REST.logDebug("SCROLL-READ from: ", room.firstMessageId);
          store.dispatch('updateRoom', this.$props.room_id);
        }
      }
      
      this.$data.scrollToBottom = (event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight);
    },
    allowStreamCreation() {
      let sysRoomName = Net.SYSTEM_ROOM_PREFIX + REST.userId()
      let room = store.getters.getRoom(this.$props.room_id) as Net.ChatRoomAndMembers;
      return ! ((room.unique_id === sysRoomName)
              || (room.unique_id === Net.AmigoSysRoom)
              || (room.unique_id === Net.KidSysRoom));
    },
    historyView(item:MessageStream) {
      this.$data.historyMsgs = DATA.addDayDivider(item.items);
      // REST.logDebug("CHAT historyView", item, this.$data.messages, this.$data.historyMsgs);
      // find real message
      this.$data.historyRoot = item.streamRoot();
      this.$data.scrollToBottomHistory = true;
      this.$data.historyDlg = true;
    },
    sendMsg(text:string, selFile:Object) {
      let msgText = text;
      if (msgText.toString().trim().length > 0) {
        let message = new DB.Message();
        message.content = msgText;
        message.type_id = (this.$data.historyRoot ? DATA.responseChatTypeId() : DATA.chatTypeId()),
        message.room_id = this.$props.room_id,
        message.ref_id  = (!this.$data.historyRoot ? 0 
                                      : (this.$data.historyRoot.ref_id > 0 ?
                                                    this.$data.historyRoot.ref_id 
                                                  : this.$data.historyRoot.id))

        if (selFile != null)
        {
          let uploadUrl = "/docs/" + Net.DocType_Chat + "/0";
          REST.call("UPLOAD", uploadUrl,
                    selFile,
                    ((r:any) => { 
                        let doc = r.detail as DB.Document
                        REST.logDebug("Chat attachment: ", doc);
                        message.attach_id = doc.id;
                        sendChatMessage(message);
                        return r;}),
                    (async (r) => { 
                        REST.logError(" uploadFile:", r);
                    }));
        } else {
          sendChatMessage(message);
        }
      }
      // this.$data.historyDlg = false;
      return "";
    }
  },
  watch: {
    taskReplyDlg: function(newVal, oldVal) {
      if (newVal == false) {
        this.$data.editTaskId = 0;
      }
    },
    eventDlg: function(newVal, oldVal) {
      if (newVal == false) {
        // Reset DocumentId is dialog closed
        this.$data.editMeetingId = 0;
      }
    },
    previewDlg: function(newVal, oldVal) {
      if (newVal == false) {
        // Reset DocumentId is dialog closed
        this.$data.previewDocId = 0;
      }
    },
    historyDlg: function(newVal, oldVal) {
      if (!newVal) {
        this.$data.historyRoot = null;
      }
    },
    room_id: function(newVal, oldVal) {
      REST.logDebug("WATCH room_id...", oldVal, "=>", newVal);
      store.dispatch('updateRoom', newVal);
      store.dispatch('clearUnreadMsgCount', newVal);
      this.$data.title = DATA.getRoomTitle(newVal);

      let tmpMsg = DATA.addDayDivider(
                          DATA.getRoomMessages(store.getters.getMessages, newVal));
      if ((newVal != oldVal) 
          || (this.$data.messages.length != tmpMsg.length))
      {
        this.$data.messages = tmpMsg;
        this.$data.scrollToBottom = true;
        this.$data.scrollToBottomHistory = true;
      }
    },
    messages: function(newVal, oldVal) {
      if (newVal.length != oldVal.length) {
        this.$nextTick(() => {
            let bottom = (this.$refs.bottom as HTMLElement)
            if (bottom && this.$data.scrollToBottom)
            {
              bottom.scrollIntoView({ behavior: 'smooth' })
            }
          })
      }
    },
    historyMsgs: function(newVal, oldVal) {
      // REST.logDebug("WATCH historyMsgs...", oldVal, "=>", newVal);
      if (this.$data.historyDlg) {
        this.$nextTick(() => {
            if (this.$data.historyDlg && this.$data.scrollToBottomHistory) {
              setTimeout(() => {
                                let historyBottom = (this.$refs.historyBottom as HTMLElement);
                                if (historyBottom) {
                                  REST.logDebug("SCROLL historyBottom")
                                  // historyList.scrollTop = historyList.scrollHeight;
                                  historyBottom.scrollIntoView({ behavior: 'smooth' })
                                } else {
                                  REST.logDebug("!!!! No historyBottom !!!!!")
                                }
                          }, 200);

            }
          })
      }
    },
  },
  computed: {
    memberName () {
        return function(id:number, isGroup:boolean) {
            if (isGroup) {
              return DATA.groupName(id);
            } else {
              return DATA.userName(id);
            }
        }
    },
    members () {
        let room : Net.ChatRoomAndMembers = store.getters.getRoom(this.$props.room_id);
        if ((room!=null) && (room.members.length > 0)) {
            let roomView = new ChatRoomView(room);
            return room.members.filter(m => m.is_member_group || roomView.showUser(DATA.getUser(m.member_id) as Net.Appuser));
        }
        REST.logDebug("updateRoom:");
        store.dispatch('updateRoom', this.$props.room_id);
        return [];
    },
  },
  created() {
    this.$data.historyRoot = null;
    this.$data.scrollToBottom = true;
    this.$data.scrollToBottomHistory = true;
    REST.logDebug("CHAT: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      let reactToEvent = false;
      if (mutation.type === 'mutateMessageArray') {
        (mutation.payload as Array<DB.Message>).forEach(m => {
          if (m.room_id == this.$props.room_id) {
            reactToEvent = true;
          }
        })
      }

      if (mutation.type === 'setMessage') {
        if ((mutation.payload as DB.Message).room_id == this.$props.room_id) {
          reactToEvent = true;
        }
      }

      if (mutation.type === 'mutateRoomArray') {
        REST.logDebug("mutateRoomArray", this.$props.room_id, mutation.payload);
        (mutation.payload as Array<Net.ChatRoomAndMembers>).forEach(r => {
            if (r.id == this.$props.room_id) {
              this.$data.title = DATA.getRoomTitle(this.$props.room_id);
              reactToEvent = true;
            }
          });
      }

      if (mutation.type === 'incUnreadMsgCount')
      {
        if ((mutation.payload as number) == this.$props.room_id) {
          reactToEvent = true;
          store.dispatch('clearUnreadMsgCount', this.$props.room_id);
        }
      }
      if (mutation.type === 'setWsState')
      {
        // in case WS connection was re-established
        // reload chat content
        store.dispatch('updateRoom', this.$props.room_id);
      }

      if (reactToEvent) {
        this.$data.messages = DATA.addDayDivider(
                                    DATA.getRoomMessages(store.getters.getMessages, this.$props.room_id));
        if (this.$data.historyDlg) {
          let tmpHist = DATA.addDayDivider(DATA.chatHistory(this.$data.historyRoot, 
                                                            this.$data.messages));
          if (this.$data.historyMsgs.length != tmpHist.length) {
            // REST.logDebug("update HISTORY:");
            this.$data.historyMsgs = tmpHist;
          }
        }
        REST.logDebug("SUBSCRIBE: ", mutation.type, " MessageCount:", this.$data.messages.length);
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
      REST.logDebug("beforeMount:");
      let tmpMsg = DATA.addDayDivider(
                          DATA.getRoomMessages(store.getters.getMessages, this.$props.room_id));
      if (this.$data.messages.length != tmpMsg.length) {
        this.$data.messages = tmpMsg;
        this.$data.scrollToBottom = true;
        this.$data.scrollToBottomHistory = true;
      }
      store.dispatch('clearUnreadMsgCount', this.$props.room_id);
      store.dispatch('updateRoom', this.$props.room_id);
  },
})
export default class Chat extends Vue {}

