



















































































































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { DATA } from '../_util/data'
import { REST } from '../_util/rest_call'
import Net from '../../../common/net_interface'
import DB from '../../../common/db_struct';

@Component({
  name: 'Questioners',
  data () {
        return {
            dialog: false,
            unsubscribe: null,
            editedItem: new Net.FillOutForm(new DB.Filloutform),
            search: '',
            fillouts: new Array<Net.FillOutForm>(),
            itemTypes: [
                // NOTE: index of this array is tored in filloutItem.item_type
                this.$t('fillout.item_type_text'),
                this.$t('fillout.item_type_radio'),
                this.$t('fillout.item_type_multisel'),
                this.$t('fillout.item_type_combo'),
                // this.$t('fillout.item_type_combo_multi'),
                // this.$t('fillout.item_type_upload'),
            ],
            headers: [
                { text: this.$t('fillout.is_deleted'), value: 'is_deleted' },
                { text: this.$t('fillout.name'), align: 'start', sortable: true, value: 'name' },
                { text: this.$t('fillout.updater'), value: 'updater_id' },
                { text: this.$t('fillout.update_time'), value: 'update_time' },
            ],
        }
  },
  computed: {
    dateToStr() { 
      return DATA.dateToStr;
    },
    userName () { 
      return DATA.userName;
    }
  },
  methods: {
    isWSConnected() {
      return DATA.isWsConnected();
    },
    closeDialog() {
        this.$data.dialog = false;
    },
    validFormData() {
        let rv = false;
        let editedItem = this.$data.editedItem as Net.FillOutForm;
        if ((editedItem.name.length > 0) && (editedItem.items.length > 0)) {
            rv = true;
            editedItem.items.forEach(i => {
                if (i.label.length == 0) {
                    rv = false;
                }
            })
        }
        return rv;
    },
    isProfileForm(item:DB.Filloutform) {
      return DATA.isProfileForm(this.$data.editedItem);
    },
    canMoveUp(item:DB.Filloutitem) {
        let editedItem = this.$data.editedItem as Net.FillOutForm;
        let rv = true;
        editedItem.items.forEach((part, index, theArray) => {
            if (part.id == item.id) {
                if (index == 0) {
                    rv = false;
                }
            }
        })
        return rv;
    },
    canMoveDown(item:DB.Filloutitem) {
        let editedItem = this.$data.editedItem as Net.FillOutForm;
        let rv = true;
        editedItem.items.forEach((part, index, theArray) => {
            if (part.id == item.id) {
                if (index == theArray.length -1) {
                    rv = false;
                }
            }
        })
        return rv;
    },
    moveItemUp(item:DB.Filloutitem) {
        let editedItem = this.$data.editedItem as Net.FillOutForm;
        editedItem.items.forEach((part, index, theArray) => {
            if (part.id == item.id) {
                let tmp = theArray[index-1];
                theArray[index-1] = theArray[index];
                theArray[index] = tmp;
            }
        });
        this.$data.editedItem = editedItem;
        this.$forceUpdate();
    },
    moveItemDown(item:DB.Filloutitem) {
        let editedItem = this.$data.editedItem as Net.FillOutForm;
        let swaped = false;
        editedItem.items.forEach((part, index, theArray) => {
            if (!swaped && (part.id == item.id)) {
                let tmp = theArray[index+1];
                theArray[index+1] = theArray[index];
                theArray[index] = tmp;
                swaped = true;
            }
        });
        this.$data.editedItem = editedItem;
        this.$forceUpdate();
    },
    newFormItem() {
        let newItem = new DB.Filloutitem();
        newItem.id = 0;
        let jsonPrefix = "JSON_"
        newItem.name = jsonPrefix + "1";
        let editedItem = this.$data.editedItem as Net.FillOutForm;
        editedItem.items.forEach(i => {
            if (i.id < newItem.id) {
                newItem.id = i.id;
            }
            if (Number(i.name.substr(jsonPrefix.length)) >= Number(newItem.name.substr(jsonPrefix.length))) {
                newItem.name = jsonPrefix + (Number(i.name.substr(jsonPrefix.length)) +1).toString();
            }
        })
        newItem.id -= 1;
        newItem.item_type = 1;
        (newItem as any).type = this.$data.itemTypes[0]
        editedItem.items.push(newItem);
        this.$data.editedItem = editedItem;
    },
    delFormItem(item:DB.Filloutitem) {
        let editedItem = this.$data.editedItem as Net.FillOutForm;
        editedItem.items = editedItem.items.filter(i => i.id != item.id);
        this.$data.editedItem = editedItem;
    },
    saveForm() {
        REST.logDebug("Save", this.$data.editedItem)
        
        let editedItem = this.$data.editedItem as Net.FillOutForm;
        editedItem.items.forEach((part, index, theArray) => {
            part.item_type = 1 + this.$data.itemTypes.findIndex((ci:any) => ci == (part as any).type);
            part.item_index = index +1;
        })
        REST.call("POST", "/filloutform",
                  editedItem,
                  (r => { REST.logDebug("SaveForm:", r);
                      this.$data.dialog = false;
                      return r.detail;
                  }),
                  (async (r) => { 
                      REST.logError(" SaveForm:", r);
                  }));
    },
    newForm() {
        this.$data.editedItem = new Net.FillOutForm(new DB.Filloutform());
        this.$data.dialog=true;
    },
    editForm(form:Net.FillOutForm) {
        let editedItem = new Net.FillOutForm(form);
        form.items.forEach(i => {
            let item = new DB.Filloutitem(i);
            (item as any).type = this.$data.itemTypes[(i.item_type -1) % this.$data.itemTypes.length]
            editedItem.items.push(item);
        })
        editedItem.items.sort((a, b) => a.item_index - b.item_index);
        this.$data.editedItem = editedItem;

        this.$data.dialog=true;
    },
    sortFillout(items:Net.FillOutForm[], sortBy: string[], sortDesc: boolean[]) {
      if ((sortBy.length == 0) || (sortDesc.length == 0)) {
        return items;
      }
      let index = sortBy[0];
      let isDesc = sortDesc[0];
      items.sort((a, b) => {
        if (index == "update_time") {
          return DATA.strCmp(DATA.dateToStr(a.update_time), DATA.dateToStr(b.update_time), isDesc);
        } else if (index == "updater_id") {
          return DATA.strCmp(DATA.getGroupOrUserName(a.updater_id, false)
                            , DATA.getGroupOrUserName(b.updater_id, false)
                            , isDesc);
        } else if (index == "is_deleted") {
          return DATA.boolCmp(a.is_deleted, b.is_deleted, isDesc);
        } else if (index == "title") {
          return DATA.strCmp(a.name, b.name, isDesc);
        }
        REST.logDebug("sortFillouts Unknown index:", index);
        return 1;
      })
      return items;
    },
  },
  created() {
    REST.logDebug("QUESTIONERS: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'mutateQuestionerArray')
      {
        this.$data.fillouts = Array.from((store.getters.getQuestioners as Map<number, Net.FillOutForm>).values());
        REST.logDebug("SUBSCRIBE: ", mutation.type, " QuestioneCount:", this.$data.fillouts);
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    store.dispatch('updateUsers');
    store.dispatch('updateQuestioners');
    this.$data.fillouts = Array.from((store.getters.getQuestioners as Map<number, Net.FillOutForm>).values());
    REST.logDebug("QUESTIONERS:beforeMount", this.$data.fillouts);
  }
})
export default class Questioners extends Vue {}
  
