


























































































































































































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { DATA } from '../_util/data'
import { REST } from '../_util/rest_call'
import DB from '../../../common/db_struct'
import Net from '../../../common/net_interface'
import { GlobalSnackBarFn } from "../_util/rest_call";

// Return true if edited user is member of group
function memberOfGroup(group: Net.UserGroupAndMembers, editedItem: Net.UpdateUser, editedMemberOf: Array<number>)
{
    if (editedItem.del_memberOf.indexOf(group.id) != -1) {
        return false;
    }
    if ((editedItem.new_memberOf.indexOf(group.id) != -1)
        || (editedMemberOf.indexOf(group.id) != -1))
    {
        return true;
    }
    return false;
}
@Component({
  name: 'Users',
  data () {
        return {
            dialog: false,
            show_pwd: false,
            unsubscribe: null,
            search: '',
            group_search: '',
            uploadCsvFile: null,
            editedItem: new Net.UpdateUser,
            users: new Array<Net.Appuser>(),
            editedMemberOf: new Array<number>(),

            headers: [
                { text: this.$t('user.name'), align: 'start', sortable: true, value: 'name' },
                { text: this.$t('user.login'), value: 'login' },
                { text: this.$t('user.email'), value: 'email' },
                { text: this.$t('user.type'), value: 'type' },
                { text: this.$t('user.is_disabled'), value: 'is_disabled' },
                { text: this.$t('user.create_time'), value: 'create_time' },
                { text: this.$t('user.update_time'), value: 'update_time' }
            ],
            group_headers: [
                { text: this.$t('user.is_member'), value: 'login' },
                { text: this.$t('groups.name'), align: 'start', sortable: true, value: 'name' }
            ]
        }
  },
  computed: {
    dateToStr() { 
      return DATA.dateToStr;
    },
    groups() {
        //return Array.from(store.getters.getNonAdminGroups.values());
        return Array.from(store.getters.getGroups.values());
    }

  },
  methods: {
    isWSConnected() {
      return DATA.isWsConnected();
    },
    close () {
        this.$data.dialog = false;
    },
    getUserAvatarLink(item: number) {
      return DATA.getUserAvatarLink(item);
    },
    getParticipantType(user_id: number) {
        return this.$t(DATA.getUserType(user_id))
    },
    isAmigoChg() {
      let editedItem = (this.$data.editedItem as Net.UpdateUser);
      if (editedItem.is_amigo) {
        editedItem.is_kid = false;
      }
      this.$data.editedItem = editedItem;
    },
    isKidChg() {
      let editedItem = (this.$data.editedItem as Net.UpdateUser);
      if (editedItem.is_kid) {
        editedItem.is_amigo = false;
        editedItem.is_admin = false;
      }
      this.$data.editedItem = editedItem;
    },
    isAdminChg() {
      let editedItem = (this.$data.editedItem as Net.UpdateUser);
      if (editedItem.is_admin) {
        editedItem.is_kid = false;
      }
      this.$data.editedItem = editedItem;
    },
    downloadKids () {
      REST.downloadKidUsers();
    },
    downloadAmigos () {
      REST.downloadNonKidUsers();
    },
    canUploadCsv() {
      return this.$data.uploadCsvFile != null;
    },
    uploadCsv () {
      let uploadFiles = this.$data.uploadCsvFile;
      REST.logDebug("uploadCsv", uploadFiles);
      let uploadUrl = "/users/bulkUpdateUsers";


      REST.call("UPLOAD", uploadUrl,
                    uploadFiles,
                    (r => { 
                        REST.logDebug("uploadCsv: ", r);
                        GlobalSnackBarFn("BULK-UPDATE Succeed:\n" + r.detail, 10000);
                        return r;
                    }),
                    (async (r) => { 
                        REST.logError(" uploadFile:", r);
                        GlobalSnackBarFn("BULK-UPDATE Failed:" + r.toString(), 10000);
                    }));

      // clear list of uploaded files
      this.$data.uploadCsvFile = null;
    },
    save () {
        if (this.$data.editedItem.id > 0) {
            // Edited item
            REST.logDebug("Save-mod", this.$data.editedItem)
            REST.call("PUT", "/users/" + this.$data.editedItem.id.toString(),
                     this.$data.editedItem,
                    (r => { REST.logDebug("EditUser:", r);
                        store.dispatch("setUser", r.detail);
                        this.$data.dialog = false;
                        return r.detail;
                    }),
                    (async (r) => { 
                        REST.logError(" EditUser:", r);
                    }));
        } else {
            // New user
            REST.logDebug("Save-new", this.$data.editedItem)
            REST.call("POST", "/users/create",
                     new Net.CreateUser(this.$data.editedItem),
                    (r => { REST.logDebug("NewUser:", r);
                        store.dispatch("setUser", r.detail);
                        this.$data.dialog = false;
                        return r.detail;
                    }),
                    (async (r) => { 
                        REST.logError(" NewUser:", r);
                    }));
        }
        
    },
    newItem() {
        this.$data.editedItem = new Net.UpdateUser;
        this.$data.editedMemberOf = new Array<number>()
        this.$data.dialog = true;
        REST.logDebug("New Item");
    },
    editItem(item: Net.Appuser) {
        this.$data.dialog = true;
        this.$data.editedItem = new Net.UpdateUser(item);
        this.$data.editedMemberOf = DATA.getGroupsOfUserId(item.id);
        REST.logDebug("Edit appUser", item, "memberOf", this.$data.editedMemberOf);
    },
    isReadonlyGroup(item: Net.UserGroupAndMembers) {
      return DATA.isReadonlyGroup(item);
    },
    userName(id:number) {
      return DATA.userName(id);
    },
    memberOf(item: Net.UserGroupAndMembers) {
        return memberOfGroup(item, this.$data.editedItem, this.$data.editedMemberOf);
    },
    toggleMembership(item: Net.UserGroupAndMembers) {
        if (this.$data.editedItem.del_memberOf.indexOf(item.id) != -1) {
            this.$data.editedItem.del_memberOf = this.$data.editedItem.del_memberOf.filter((i: number) => i != item.id);
        } else if (this.$data.editedItem.new_memberOf.indexOf(item.id) != -1) {
            this.$data.editedItem.new_memberOf = this.$data.editedItem.new_memberOf.filter((i: number) => i != item.id);
        } else
        if ((this.$data.editedMemberOf.indexOf(item.id) != -1)) {
            this.$data.editedItem.del_memberOf.push(item.id);
        } else {
            this.$data.editedItem.new_memberOf.push(item.id);
        }
    },
    sortUsers(items:Net.Appuser[], sortBy: string[], sortDesc: boolean[]) {
      if ((sortBy.length == 0) || (sortDesc.length == 0)) {
        return items;
      }

      let index = sortBy[0];
      let isDesc = sortDesc[0];
      items.sort((a, b) => {
        if (index == "name") {
          return DATA.strCmp(DATA.userName(a.id), DATA.userName(b.id), isDesc);
        } else if (index == "login") {
          return DATA.strCmp(a.login, b.login, isDesc);
        } else if (index == "email") {
          return DATA.strCmp(a.email, b.email, isDesc);
        } else if (index == "create_time") {
          return DATA.strCmp(DATA.dateToStr(a.create_time), DATA.dateToStr(b.create_time), isDesc);
        } else if (index == "update_time") {
          return DATA.strCmp(DATA.dateToStr(a.update_time), DATA.dateToStr(b.update_time), isDesc);
        }  else if (index == "type") {
          return DATA.strCmp(DATA.getUserType(a.id), DATA.getUserType(b.id), isDesc);
        } else if (index == "is_disabled") {
          return DATA.boolCmp(a.is_disabled, b.is_disabled, isDesc);
        } else if (index == "avatar") {
          return 0;
        }
        REST.logError(" sortUsers Unknown index:", index);
        return 0;
      })
      return items;
    },
    filterUsers(value: any, search: string | null, item:Net.Appuser) {
      if ((search == null) || (search.length == 0)) {
        return true;
      }
      return DATA.filterUser(item, search);
    },
    filteredUsers(content:Net.Appuser[]) {
      // called when table filtering changes
      // REST.logDebug("Filtered", content)
    },
    sortGroups(items:Net.UserGroupAndMembers[], sortBy: string[], sortDesc: boolean[]) {
      if ((sortBy.length == 0) || (sortDesc.length == 0)) {
        return items;
      }

      let index = sortBy[0];
      let isDesc = sortDesc[0];
      items.sort((a, b) => {
        if (index == "name") {
          return DATA.strCmp(a.name, b.name, isDesc);
        } else if (index == "login") {
          return DATA.boolCmp(memberOfGroup(a, this.$data.editedItem, this.$data.editedMemberOf),
                              memberOfGroup(b, this.$data.editedItem, this.$data.editedMemberOf),
                              isDesc);
        }
        REST.logError(" sortGroups Unknown index:", index);
        return 0;
      })
      return items;
    },
    filterGroups(value: any, search: string | null, item:Net.UserGroupAndMembers) {
      if ((search == null) || (search.length == 0)) {
        return true;
      }
      return item.name.toUpperCase().includes(search.toUpperCase());
    }
  },
  created() {
    REST.logDebug("USERS: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      REST.logDebug("USERS/subscribe=", mutation, ", state=", state)
      if ((mutation.type === 'mutateUserArray') 
          || (mutation.type === 'setUser'))
      {
        this.$data.users = Array.from(store.getters.getUsers.values());
        REST.logDebug("SUBSCRIBE: ", mutation.type, " UserCount:", this.$data.users.length);
      }
      
      // The below didn't help in refreshing group membership info
      if (mutation.type === 'mutateGroupArray'){
        if (this.$data.editedItem.id != 0){
          this.$data.editedMemberOf = DATA.getGroupsOfUserId(this.$data.editedItem.id)
        }
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    store.dispatch('updateGroups');
    this.$data.users = Array.from(store.getters.getUsers.values());
    REST.logDebug("USERS:beforeMount", this.$data.users.length);
  }
})
export default class Users extends Vue {}
  
