



































import { Component, Vue } from 'vue-property-decorator';
import { DATA } from '../_util/data'

@Component({
  name: 'DateTime',
  props: {
    date: Date,
    label: String,
    disabled: { type: Boolean, default: false},
    minDate: String,
    maxDate: String,
    noTime: Boolean,
    noDate: Boolean
  },
  data() {
      return {
        intDateTime: new Date(),
        intTimeStr:"00:00",
        intDateStr: new Date().toISOString().substr(0, 10),
        timeMenu: false,
        dateMenu: false
    }
  },
  beforeMount() {
      // REST.logDebug("beforeMount date...", this.$props.date);
      this.$data.intDateTime = new Date(this.$props.date)
      this.$data.intDateStr = DATA.dateToStrIso(this.$data.intDateTime);
      this.$data.intTimeStr = DATA.dateToTimeStrNoSec(this.$data.intDateTime);
  },
  watch: {
    date: function(newVal, oldVal) {
      this.$data.intDateTime = new Date(newVal);
      this.$data.intDateStr = DATA.dateToStrIso(this.$data.intDateTime);
      this.$data.intTimeStr = DATA.dateToTimeStrNoSec(this.$data.intDateTime);
    },
    intDateStr: function(newVal, oldVal) {
      if (newVal != oldVal) {
        let newDate = new Date(newVal);
        newDate.setHours(this.$data.intDateTime.getHours())
        newDate.setMinutes(this.$data.intDateTime.getMinutes())
        if (this.$data.intDateTime.getTime() !== newDate.getTime()) {
          this.$emit('dateChanged', newDate)
        }
      }
    },
    intTimeStr: function(newVal, oldVal) {
      if (newVal != oldVal) {
        let newDate = new Date(this.$data.intDateTime);
        newDate.setHours(Number(newVal.split(":")[0]))
        newDate.setMinutes(Number(newVal.split(":")[1]))
        if (this.$data.intDateTime.getTime() !== newDate.getTime()) {
          this.$emit('dateChanged', newDate)
        }
      }
    },
  },
})
export default class DateTime extends Vue {}

