



















































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { REST } from '../_util/rest_call'
import { DATA } from '../_util/data'
import DB from '../../../common/db_struct'
import Net from '../../../common/net_interface'
import MeetingEdit from './MeetingEdit.vue'

@Component({
  name: 'Meetings',
  components: {
    MeetingEdit
  },
  props: {
    updateEventRangeFn: Function,
    newEventType: String,
  },
  data () {
        return {
            editEvent: false,
            editMeetingId: 0,
            viewDay: '',
            unsubscribe: null,
            shownEvents: [],
            from_date: null,
            to_date: null,
            editEventType: "PRIVATE",
        }
  },
  methods: {
    closeDialod() {
      this.$data.editMeetingId=-1
      this.$data.editEvent=false
    },
    enableNewEvent() {
      if (REST.isKid()) {
        // kids cannot create events
        return false;
      }

      if (this.$props.newEventType == Net.MeetType_GroupEvent) {
        let selDate = new Date(this.$data.viewDay);
        let rv = false;
        store.getters.getTopics.forEach((value:DB.Monthlytopic) => {
          if ((selDate >= new Date(new Date(value.start_time).toISOString().substr(0, 10)))
            && (selDate <= new Date(new Date(value.end_time).toISOString().substr(0, 10))))
          {
            rv = true;
          }
        });
        return rv;
      }
      return true;
    },
    showEvent ({ nativeEvent, event }) {
      REST.logDebug("showEvent", event)
      this.$data.editMeetingId = event.meeting_details.id;
      this.$nextTick(() => { this.$data.editEvent = true }); 
      nativeEvent.stopPropagation()
    },
    newEvent() {
      REST.logDebug("newEvent", event)
      this.$data.editMeetingId = 0;
      this.$data.editEvent = true; 
    },
    setToday () {
      this.$data.viewDay = ''
    },
    prevMonth () {
      (this.$refs.calendar as any).prev()
    },
    nextMonth () {
      (this.$refs.calendar as any).next()
    },
    updateRange ({ start, end }) {
        this.$data.from_date = start;
        this.$data.to_date = end;
        this.$data.shownEvents = this.$props.updateEventRangeFn(this.$data.from_date, this.$data.to_date);
    },
    getEventColor(item: any) {
      return DATA.getMeetingColor(item.meeting_details);
    },
  },
  mounted () {
      (this.$refs.calendar as any).checkChange()
  },
  created() {
    REST.logDebug("MEETINGS: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      REST.logDebug("SUBSCRIBE-Meetings: ", mutation.type);
      if ((mutation.type === 'mutateTopicArray')
          || (mutation.type === 'deleteMeeting')
          || (mutation.type === 'mutateMeetingArray')
          || (mutation.type === 'setMeeting'))
      {
        if (this.$data.from_date != null) {
          this.$data.shownEvents = this.$props.updateEventRangeFn(this.$data.from_date, this.$data.to_date);
        } else {
          // force updating shown evenets
          setTimeout(() => {
              (this.$refs.calendar as any).next();
              setTimeout(() => {
                  (this.$refs.calendar as any).prev();
                }, 10)
            }, 10);
          (this.$refs.calendar as any).checkChange();
        }
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    store.dispatch('updateTopics');
    store.dispatch('updateMeetings');
    REST.logDebug("MEETINGS:beforeMount", this.$data.topics);
  }
})
export default class Meetings extends Vue {}
