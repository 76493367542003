














































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { REST } from '../_util/rest_call'
import { DATA, KeyedInvitee, ChatRoomSelectFn } from '../_util/data'
import Net from '../../../common/net_interface'
import DB from '../../../common/db_struct';

@Component({
  name: 'UploadReplyEdit',
  props: {
    taskReplyId:Number,
    uploadClickedFn:Function
  },
  watch: {
    taskReplyId: function(newVal, oldVal) {
        this.$data.newOrEdit(newVal);
    }
  },
  data () {
        return {
            unsubscribe: null,
            uploadFilesArray: [],
            replyDocuments: new Array<DB.Document>(),
            document_header: [
                { text: this.$t('document_name'), align: 'start', sortable: true, value: 'description' },
                { text: this.$t('document_size'), value: 'file_size' },
                { text: this.$t('document_upload_time'), value: 'upload_time' },
                { text: this.$t('document_is_deleted'), value: 'is_deleted' },
            ],
        }
  },
  methods: {
    canDeleteRestore(item:DB.Document) {
      return true;  // Fot now
    },
    deleteDocument(item:DB.Document) {
        if (confirm(this.$t('confirm_doc_delete') + item.description))
        {
          REST.logDebug("deleteDocument", item);
          let uploadUrl = "/docs/" + item.id;
          REST.call("DELETE", uploadUrl,
                        new Boolean(true),
                        (r => { 
                            REST.logDebug("Deleted: ", r);
                            this.$data.newOrEdit(this.$props.taskReplyId);
                            return r;
                        }),
                        (async (r) => { 
                            REST.logError(" deleteDocument:", r);
                        }));
        }
    },
    restoreDocument(item:DB.Document) {
        if (confirm(this.$t('confirm_doc_restore') + item.description))
        {
          REST.logDebug("restoreDocument", item);
          let uploadUrl = "/docs/" + item.id;
          REST.call("DELETE", uploadUrl,
                        new Boolean(false),
                        (r => { 
                            REST.logDebug("Restore: ", r);
                            this.$data.newOrEdit(this.$props.taskReplyId);
                            return r;
                        }),
                        (async (r) => { 
                            REST.logError(" restoreDocument:", r);
                        }));
        }
    },
    isUploadFileSelected() {
      return this.$data.uploadFilesArray.length > 0;
    },
    onUploadFileClick() {
        this.$props.uploadClickedFn(this.$data.uploadFilesArray);
        // clear list of uploaded files
        this.$data.uploadFilesArray = [];
    },
    
    sortDocuments(items:DB.Document[], sortBy: string[], sortDesc: boolean[]) {
      if ((sortBy.length == 0) || (sortDesc.length == 0)) {
        return items;
      }
      let index = sortBy[0];
      let isDesc = sortDesc[0];
      items.sort((a, b) => {
        if (index == "description") {
          return DATA.strCmp(a.description, b.description, isDesc);
        } else if (index == "upload_time") {
          return DATA.strCmp(DATA.dateToStr(a.upload_time), DATA.dateToStr(b.upload_time), isDesc);
        } else if (index == "is_deleted") {
          return DATA.boolCmp(a.is_deleted, b.is_deleted, isDesc);
        }
        REST.logDebug("sortDocuments Unknown index:", index);
        return 1;
      })
      return items;
    },
    downloadDocument (item:DB.Document) {
      REST.downloadDocument(item.id);
    },
    dateToStr(str) { 
      return DATA.dateToStr(str);
    },
    getDocumentSize(size:number) {
      return DATA.fileSizeToString(size);
    },
  },
  created() {
    REST.logDebug("MEETINGEDIT: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      REST.logDebug("SUBSCRIBE-Meetings: ", mutation.type);
      if ((mutation.type === 'mutateTaskArray')
          || (mutation.type === 'setTaskReply')
          || (mutation.type === 'setTask')
          || (mutation.type === 'deleteTask'))
      {
        if (this.$props.taskReplyId > 0) {
            this.$data.newOrEdit(this.$props.taskReplyId);
        }
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    this.$data.newOrEdit = (taskReplyId:number) => {
        this.$data.replyDocuments = new Array<DB.Document>();
        if (taskReplyId > 0) {
            DATA.getTaskReplyDocuments(taskReplyId, this.$data.replyDocuments);
        }
    }
    this.$data.newOrEdit(this.$props.taskReplyId);

    REST.logDebug("UploadReply:beforeMount", this.$props.taskReplyId);
  }
})
export default class UploadReplyEdit extends Vue {}
