












































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { REST } from '../_util/rest_call'
import { DATA, KeyedInvitee, ChatRoomSelectFn } from '../_util/data'
import Net from '../../../common/net_interface'
import DB from '../../../common/db_struct';
import DateTime from './DateTime.vue'
import Invitees from './Invitees.vue'
import TaskEdit from './TaskEdit.vue'

function buildTopicComboContent(topics:Array<DB.Monthlytopic>,
                                monthly_topic_id:number,
                                viewDay:string)
{
  let topicsCombo : any[] = [];
  let topicsComboSelection:null|{ text: string; value: number; disabled: boolean; } = null;
  let findClosestToDate = monthly_topic_id == 0;
  let closestDate = new Date(0);
  let initDate = new Date(Date.now());
  if (viewDay != "") {
    initDate = new Date(viewDay);
  }

  topics.forEach((t:DB.Monthlytopic) => {
    if ((t.is_active)
        || (monthly_topic_id == t.id))
    {
      let topicStartDate = new Date(t.start_time);
      let topicEndDate = new Date(t.end_time);
      let topicComboItem = {text: ("[" + DATA.dateToStr(topicStartDate) 
                              + " - " + DATA.dateToStr(topicEndDate)
                              + "] " + t.title),
                        value: t.id,
                        disabled: false};
      topicsCombo.push(topicComboItem);
      if (findClosestToDate) {
        if ((monthly_topic_id == 0)
            || (Math.abs(initDate.getTime() - topicStartDate.getTime())
              < Math.abs(initDate.getTime() - closestDate.getTime())))
        {
          closestDate = topicStartDate;
          topicsComboSelection = topicComboItem;
        }
      } else if (monthly_topic_id == t.id) {
          topicsComboSelection = topicComboItem;
      }
    }
  });

  // sort topics by date
  topicsCombo = topicsCombo.sort((a, b) => a.text.localeCompare(b.text) );
  return { topicsCombo, topicsComboSelection };
}

function meetingHasBegun(meeting: Net.MeetingAndInvitees) : Boolean {
        // check that the meeting has begun
        let startTime = new Date(meeting.start_time);
        let currentTime = new Date();
        return startTime <= currentTime;
}

function canSeeParticipants(meeting: Net.MeetingAndInvitees) : Boolean {
    if (meeting.id != 0) {                           // Not new Event
      return REST.isAdmin()                          // User is admin
            || (meeting.creator_id == REST.userId()) // Is creator
            || (!REST.isKid()                        // User is not kid
              && !meeting.hide_participants);        // Participants not hidden from Aigos
    }
    return false;
}

function adminOrCreator(meeting: Net.MeetingAndInvitees) : Boolean {
  return ((meeting.creator_id == REST.userId())
          || REST.isAdmin()
          || (meeting.id == 0))
}

@Component({
  name: 'MeetingEdit',
  components: {
    DateTime,
    Invitees,
    TaskEdit
  },
  props: {
    meetingId:Number,
    closeDialogFn:Function,
    newEventType: { type: String, default: "PRIVATE"},
    viewDay:{ type: String, default: ""}
  },
  watch: {
    meetingId: function(newVal, oldVal) {
        this.$data.newOrEdit(newVal);
    },
    topicsComboSelection: function(newValue, oldValue) {
      if ((this.$data.editEventType == Net.MeetType_GroupEvent)
          && (newValue)
          && (this.$props.meetingId == 0))
      {
        // For New MonthlyEvents set default description
        // to be Monthly topic description
        let topic_id = newValue.value;
        let topic = store.getters.getTopics.get(Number(topic_id)) as DB.Monthlytopic;
        if (topic) {
          this.$data.editedEvent.description = topic.description
        }
      }
    }
  },
  data () {
        return {
            taskDialog: false,
            editTaskId: 0,
            participant_search: '',
            task_search: '',
            tab: '',
            newOrEdit: null,
            unsubscribe: null,
            topicsCombo: [],
            topicsComboSelection: null,
            uploadDocTypes: [],
            uploadDocTypeComboSel: null,
            selectedInvitees: [],
            uploadFilesArray: [],
            eventDocuments: new Array<DB.Document>(),
            eventTasks: [],
            usersAndGroups: new Array<DB.Meetinginvitee>(),
            editEventType: "PRIVATE",
            editEventRecurrence: "ONCE",
            editedEvent: new Net.MeetingAndInvitees(new DB.Meeting()),
            document_header: [
                { text: this.$t('document_name'), align: 'start', sortable: true, value: 'description' },
                { text: this.$t('document_type'), value: 'type_id' },
                { text: this.$t('document_size'), value: 'file_size' },
                { text: this.$t('document_uploader'), value: 'uploader_id' },
                { text: this.$t('document_upload_time'), value: 'upload_time' },
                { text: this.$t('document_is_deleted'), value: 'is_deleted' },
            ],
            tasks_headers: [
                { text: this.$t('tasks.is_active'), value: 'is_active' },
                { text: this.$t('tasks.title'), align: 'start', sortable: true, value: 'title' },
                { text: this.$t('tasks.dead_line'), value: 'dead_line' },
               // { text: this.$t('tasks.updater'), value: 'updater_id' },
               // { text: this.$t('tasks.update_time'), value: 'update_time' },
            ],
        }
  },
  computed: {
     attendance_header() {
       return [ { text: this.$t('invitee_name'), align: 'start', sortable: true, value: 'name' },
                { text: this.$t('invitee_type'), value: 'type' },
                { text: this.$t('invitee_telefon'), value: 'phone' },
                { text: this.$t('invitee_email'), value: 'email' },
                ... meetingHasBegun(this.$data.editedEvent) ? [{ text: this.$t('invitee_attendance_comment'), value: 'attendance_comment' }] : [],
                ... meetingHasBegun(this.$data.editedEvent) ? [{ text: this.$t('invitee_attended'), value: 'has_attended' }] : [],
            ];
     },
    validStartEndTime() {
      let startTime = new Date(this.$data.editedEvent.start_time);
      let endTime = new Date(this.$data.editedEvent.end_time);
      if (startTime > endTime) {
        return false;
      }

      if ((this.$data.editEventType == Net.MeetType_GroupEvent)
          && (this.$data.topicsComboSelection))
      {
        let topic_id = this.$data.topicsComboSelection.value;
        let topic = store.getters.getTopics.get(Number(topic_id)) as DB.Monthlytopic;
        let topicStart = new Date(topic.start_time);
        let topicEnd = new Date(topic.end_time);
        if ((startTime < topicStart) || (endTime > topicEnd)) {
          return false;
        }
      }
      return true;
    },
    minDateRestriction() {
      if ((this.$data.editEventType == Net.MeetType_GroupEvent)
          && (this.$data.topicsComboSelection))
      {
        let topic_id = this.$data.topicsComboSelection.value;
        let topic = store.getters.getTopics.get(Number(topic_id)) as DB.Monthlytopic;
        if (topic) {
          return new Date(topic.start_time).toISOString().substr(0, 10)
        }
      }
      return undefined;
    },
    maxDateRestriction() {
      if ((this.$data.editEventType == Net.MeetType_GroupEvent)
          && (this.$data.topicsComboSelection))
      {
        let topic_id = this.$data.topicsComboSelection.value;
        let topic = store.getters.getTopics.get(Number(topic_id)) as DB.Monthlytopic;
        if (topic) {
          return new Date(topic.end_time).toISOString().substr(0, 10)
        }
      }
      return undefined;
    }
  },
  methods: {
    getAmigoParticipantsCount() {
      return "(" + DATA.getAmigoParticipantsCount(this.$data.editedEvent) + ") ";
    },
    getKidParticipantsCount() {
      return "(" + DATA.getKidParticipantsCount(this.$data.editedEvent) + ") ";
    },
    isWSConnected() {
      return DATA.isWsConnected();
    },
    closeTaskEdit() {
      this.$data.taskDialog = false;
    },
    canDeleteRestore(item:DB.Document) {
      return (REST.isAdmin() || (item.uploader_id == REST.userId()))
    },
    deleteDocument(item:DB.Document) {
        if (confirm(this.$t('confirm_doc_delete') + item.description))
        {
          // REST.logDebug("deleteDocument", item);
          let uploadUrl = "/docs/" + item.id;
          REST.call("DELETE", uploadUrl,
                        new Boolean(true),
                        (r => { 
                            // REST.logDebug("Deleted: ", r);
                            this.$data.eventDocuments = new Array<DB.Document>();
                            DATA.getMeetingDocuments(this.$data.editedEvent.id, this.$data.eventDocuments);
                            return r;
                        }),
                        (async (r) => { 
                            REST.logError(" deleteDocument:", r);
                        }));
        }
    },
    restoreDocument(item:DB.Document) {
        if (confirm(this.$t('confirm_doc_restore') + item.description))
        {
          // REST.logDebug("restoreDocument", item);
          let uploadUrl = "/docs/" + item.id;
          REST.call("DELETE", uploadUrl,
                        new Boolean(false),
                        (r => { 
                            // REST.logDebug("Restore: ", r);
                            this.$data.eventDocuments = new Array<DB.Document>();
                            DATA.getMeetingDocuments(this.$data.editedEvent.id, this.$data.eventDocuments);
                            return r;
                        }),
                        (async (r) => { 
                            REST.logError(" restoreDocument:", r);
                        }));
        }
    },
    isUploadFileSelected() {
      return this.$data.uploadFilesArray.length > 0;
    },
    onUploadFileClick() {
        let uploadFiles = this.$data.uploadFilesArray;
        // REST.logDebug("onUploadFileClick", uploadFiles);
        let uploadUrl = "/docs/" + this.$data.uploadDocTypeComboSel.value + "/";
        uploadUrl += this.$data.editedEvent.id
        uploadFiles.forEach((file:any) => {
            REST.call("UPLOAD", uploadUrl,
                      file,
                      (r => { 
                          // REST.logDebug("Uploaded: ", r);
                          this.$data.eventDocuments = new Array<DB.Document>();
                          DATA.getMeetingDocuments(this.$data.editedEvent.id, this.$data.eventDocuments);
                          return r;
                      }),
                      (async (r) => { 
                          REST.logError(" uploadFile:", r);
                      }));
        })

        // clear list of uploaded files
        this.$data.uploadFilesArray = [];
    },
    getParticipantType(user_id: number) {
        return this.$t(DATA.getUserType(user_id))
    },
    sortParticipants(items:DB.Meetingparticipant[], sortBy: string[], sortDesc: boolean[]) {
      if ((sortBy.length == 0) || (sortDesc.length == 0)) {
        return items;
      }
      let index = sortBy[0];
      let isDesc = sortDesc[0];
      items.sort((a, b) => {
        if (index == "accepted") {
          return DATA.strCmp(DATA.hasAcceptedIcon(a.user_id, false, this.$data.editedEvent.participants)
                            , DATA.hasAcceptedIcon(b.user_id, false, this.$data.editedEvent.participants)
                            , isDesc);
        } else if (index == "name") {
          return DATA.strCmp(DATA.userName(a.user_id), DATA.userName(b.user_id), isDesc);
        } else if (index == "type") {
          return DATA.strCmp(DATA.getUserType(a.user_id), DATA.getUserType(b.user_id), isDesc);
        } else if (index == "phone") {
          return DATA.strCmp(DATA.userPhone(a.user_id), DATA.userPhone(b.user_id), isDesc);
        } else if (index == "email") {
          return DATA.strCmp(DATA.userEmail(a.user_id), DATA.userEmail(b.user_id), isDesc);
        }
        REST.logError(" sortParticipants Unknown index:", index);
        return 1;
      })
      return items;
    },
    sortDocuments(items:DB.Document[], sortBy: string[], sortDesc: boolean[]) {
      if ((sortBy.length == 0) || (sortDesc.length == 0)) {
        return items;
      }
      let index = sortBy[0];
      let isDesc = sortDesc[0];
      items.sort((a, b) => {
        if (index == "description") {
          return DATA.strCmp(a.description, b.description, isDesc);
        } else if (index == "uploader_id") {
          return DATA.strCmp(DATA.userName(a.uploader_id), DATA.userName(b.uploader_id), isDesc);
        } else if (index == "upload_time") {
          return DATA.strCmp(DATA.dateToStr(a.upload_time), DATA.dateToStr(b.upload_time), isDesc);
        } else if (index == "is_deleted") {
          return DATA.boolCmp(a.is_deleted, b.is_deleted, isDesc);
        }
        REST.logError(" sortDocuments Unknown index:", index);
        return 1;
      })
      return items;
    },
    downloadDocument (item:DB.Document) {
      REST.downloadDocument(item.id);
    },
    downloadAllReplies() {
      REST.downloadMeetingReplies(this.$data.editedEvent.id);
    },
    topicEventOnly() {
      return this.$data.editEventType == Net.MeetType_GroupEvent;
    },
    canAccept() {
      let editMeeting = this.$data.editedEvent as Net.MeetingAndInvitees;
      return ((new Date(editMeeting.end_time) > new Date())
              && (DATA.getParticipationState(REST.userId(), editMeeting) != DATA.acceptedTypeId())
              && ((REST.isKid() 
                      && editMeeting.type_id == DATA.groupOccupationTypeId()
                      && ((editMeeting.max_child_cnt > DATA.getKidParticipantsCount(editMeeting))
                          || (editMeeting.max_child_cnt == 0)))
                  || DATA.isMeetingInvitee(REST.userId(), editMeeting)));
    },
    acceptBtnText() {
      let editMeeting = this.$data.editedEvent as Net.MeetingAndInvitees;
      if ((editMeeting.type_id == DATA.groupOccupationTypeId())
        && (REST.isKid()))
      {
        return this.$t('event_subscribe_btn');
      }
      return this.$t('event_accept_btn')
    },
    canDecline() {
      let editMeeting = this.$data.editedEvent as Net.MeetingAndInvitees;
      let stateId = DATA.getParticipationState(REST.userId(), editMeeting);
      return ((new Date(editMeeting.end_time) > new Date())
              && (((stateId!=0) && (stateId != DATA.declinedTypeId()))
                  || (DATA.isMeetingInvitee(REST.userId(), editMeeting)
                      && (stateId != DATA.declinedTypeId()))));
    },
    declineBtnText() { 
      let editMeeting = this.$data.editedEvent as Net.MeetingAndInvitees;
      if ((editMeeting.type_id == DATA.groupOccupationTypeId())
        && (REST.isKid()))
      {
        return this.$t('event_unsubscribe_btn');
      }
      return this.$t('event_decline_btn');
    },
    canChat() {
      let editMeeting = this.$data.editedEvent as Net.MeetingAndInvitees;
      return ((editMeeting.id>0) 
            && (REST.isAdmin() || DATA.isMeetingInvitee(REST.userId(), editMeeting)
              || DATA.isMeetingParticipant(REST.userId(), editMeeting)));
    },
    chatBtnText() { 
      return this.$t('event_chat_btn')
    },
    canTentative() {
      let editMeeting = this.$data.editedEvent as Net.MeetingAndInvitees;
      if ((new Date(editMeeting.end_time) < new Date())
          || ((editMeeting.type_id == DATA.groupOccupationTypeId()) && REST.isKid()))
      {
        // no tentative for group occupation for kids
        return false;
      }
      return DATA.isMeetingInvitee(REST.userId(), editMeeting)
              && (DATA.getParticipationState(REST.userId(), editMeeting) != DATA.tentativeTypeId());
    },
    tentativeBtnText() { 
      return this.$t('event_tentative_btn')
    },
    canSeeInvitees() {
      return (this.$data.editedEvent.id==0)                           // Creating new event
             || REST.isAdmin()                                        // User is admin
             || (this.$data.editedEvent.creator_id == REST.userId())  // Is creator
             || (!REST.isKid()                                        // User is not kid
                && !this.$data.editedEvent.hide_participants);        // Participants not hidden from Aigos
    },
    canSeeParticipants() {
      return canSeeParticipants(this.$data.editedEvent);
    },
    meetingHasBegun() : Boolean{
      return meetingHasBegun(this.$data.editedEvent);
    },
    canSetAttendance() {
      return canSeeParticipants(this.$data.editedEvent) &&
             meetingHasBegun(this.$data.editedEvent);
    },
    filterParticipants(value: any, search: string | null, item:DB.Meetingparticipant) {
      if (search != null) {
        return DATA.filterUser(DATA.getUser(item.user_id), search);
      }
      else {
        return true;
      }
    },

    isAdmin() {
      return REST.isAdmin();
    },
    adminOrCreator() {
      return adminOrCreator(this.$data.editedEvent);
    },
    acceptEvent() {
      REST.call("PUT", "/meetings/accept/"
              + (this.$data.editedEvent as Net.MeetingAndInvitees).id, null,
        (r => {
            return r.detail;
        }),
        (async (r) => { 
            REST.logError(" acceptEvent:", r);
        }));
    },
    declineEvent() {
      REST.call("PUT", "/meetings/decline/"
              + (this.$data.editedEvent as Net.MeetingAndInvitees).id, null,
        (r => {
            return r.detail;
        }),
        (async (r) => { 
            REST.logError(" declineEvent:", r);
        }));
    },
    tentativeEvent() {
      REST.call("PUT", "/meetings/tentative/"
              + (this.$data.editedEvent as Net.MeetingAndInvitees).id, null,
        (r => {
            return r.detail;
        }),
        (async (r) => { 
            REST.logError(" tentativeEvent:", r);
        }));
    },
    chatEvent() {
      let editMeeting = this.$data.editedEvent as Net.MeetingAndInvitees;
      let roomId = DATA.meetingChatRoomId(editMeeting);
      if (Number(roomId) > 0) {
        this.$props.closeDialogFn();
        this.$nextTick(() => { ChatRoomSelectFn(roomId); });
      } else {
        REST.call("PUT", "/meetings/chat/"
                + (this.$data.editedEvent as Net.MeetingAndInvitees).id, null,
          (r => {
              return r.detail;
          }),
          (async (r) => { 
              REST.logError(" meetingroom:", r);
          }));
      }
    },
    saveEvent () {
      let method = "PUT"
      let url = "/meetings"
      let payload:any = null;
      let editedEvent = (this.$data.editedEvent as Net.MeetingAndInvitees);
      REST.logDebug("saveEvent", this.$data.topicsComboSelection, editedEvent);
      if (this.$data.editEventType == Net.MeetType_GroupEvent)
      {
        editedEvent.monthly_topic_id = this.$data.topicsComboSelection.value;
      } else {
        editedEvent.monthly_topic_id = 0;
      }
      editedEvent.type_id = DATA.getNomenCodeId(store.getters.nomenclator.meetingTypes,
                                                this.$data.editEventType);
      editedEvent.recurrence_id = DATA.getNomenCodeId(store.getters.nomenclator.meetingRecurrences,
                                                this.$data.editEventRecurrence);

      if (adminOrCreator(editedEvent)) {
        // set invitees
        if (editedEvent.id > 0) {
            // Edited topic
            url += "/" + editedEvent.id.toString()
            payload = new Net.MeetingAmend(editedEvent);
            payload.new_invitees = [];
            payload.del_invitees = [];
            this.$data.selectedInvitees.forEach((i:KeyedInvitee) => {
              let exist = editedEvent.invitees.find(ei => ((ei.is_member_group == i.data.is_member_group)
                                                            && (ei.member_id == i.data.member_id)));
              if (!exist) {
                payload.new_invitees.push(i.data);
              }
            });
            editedEvent.invitees.forEach(ei => {
              let exist = this.$data.selectedInvitees.find((i:KeyedInvitee) => ((ei.is_member_group == i.data.is_member_group)
                                                            && (ei.member_id == i.data.member_id)));
              if (!exist) {
                payload.del_invitees.push(ei);
              }
            })
        } else {
            // New topic
            editedEvent.invitees = [];
            this.$data.selectedInvitees.forEach((i:KeyedInvitee) => {
              editedEvent.invitees.push(i.data);
            });
            payload = editedEvent;
            method = "POST"
        }
        REST.call(method, url, payload,
                (r => {
                    return r.detail;
                }),
                (async (r) => { 
                    REST.logError(" saveEvent:", r);
                }));
      }

      // Save attendance
      if ((editedEvent.id > 0) && meetingHasBegun(editedEvent) && canSeeParticipants(editedEvent)) {
        let method = "PUT"
        let url = "/meetings/attendance"
        let payload:any = null;
        REST.logDebug("setAttencanceEvent", editedEvent);
        url += "/" + editedEvent.id.toString()
        payload = editedEvent;
        REST.call(method, url, payload,
                (r => {
                    return r.detail;
                }),
                (async (r) => { 
                    REST.logError(" setAttendanceEvent:", r);
                }));
      }

      // Close event dialog
      this.$props.closeDialogFn();
    },
    isAdminIcon(userId:number, isGroup:boolean) {
        return DATA.getTableIcon(userId, isGroup,
                                (u:Net.Appuser) => u.is_admin);
    },
    isAmigoIcon(userId:number, isGroup:boolean) {
        return DATA.getTableIcon(userId, isGroup,
                                (u:Net.Appuser) => u.is_amigo);
    },
    isKidIcon(userId:number, isGroup:boolean) {
        return DATA.getTableIcon(userId, isGroup,
                                (u:Net.Appuser) => u.is_kid);
    },
    hasAcceptedIcon(userId:number, isGroup:boolean) {
      let rv = DATA.hasAcceptedIcon(userId, isGroup, this.$data.editedEvent.participants);
      // REST.logDebug("hasAcceptedIcon", userId, isGroup, " -> ", rv);
      return rv;
    },
    dateToStr(str) { 
      return DATA.dateToStr(str);
    },
    dateToDateTimeStr(str) { 
      return DATA.dateToStr(str) + " " + DATA.dateToTimeStr(str);
    },
    getDocumentType(typeId:number) {
      let meetingTypeCode = DATA.getNomenIdCode(store.getters.nomenclator.documentTypes, typeId);
      let rv = this.$t('doc_type_' + meetingTypeCode);
      return rv;
    },
    getDocumentSize(size:number) {
      return DATA.fileSizeToString(size);
    },
    getInviteeName(userId:number, isGroup:boolean) {
      return DATA.getGroupOrUserName(userId, isGroup);
    },
    getParticipantPhone(userId:number) {
      return DATA.userPhone(userId);
    },
    getParticipantEmail(userId:number) {
      return DATA.userEmail(userId);
    },
    userName(userId:number) {
      return DATA.userName(userId);
    },
    isInvited(item: DB.Meetinginvitee) {
      return DATA.isInvited(item, this.$data.selectedInvitees)
    },
    inviteeCount() {
      return this.$data.selectedInvitees.length;
    },
  },
  created() {
    // REST.logDebug("MEETINGEDIT: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      // REST.logDebug("SUBSCRIBE-Meetings: ", mutation.type);

      // TODO: react only if edited meeting affected
      if ((mutation.type === 'mutateTaskArray')
          || (mutation.type === 'setTaskReply')
          || (mutation.type === 'setTask')
          || (mutation.type === 'deleteTask'))
      {
        this.$data.eventTasks = DATA.getMeetingTasks(this.$data.editedEvent.id);
      }

      if ((mutation.type === 'mutateUserArray') 
          || (mutation.type === 'setUser')
          || (mutation.type === 'mutateGroupArray'))
      {
        this.$data.usersAndGroups = DATA.getMeetingPossibleInvitees(this.$data.editedEvent);
      }

      if ((mutation.type === 'mutateTopicArray')
          || (mutation.type === 'deleteMeeting')
          || (mutation.type === 'mutateMeetingArray')
          || (mutation.type === 'setMeeting'))
      {
        let meetingDetails = store.getters.getMeeting(this.$data.editedEvent.id) as Net.MeetingAndInvitees;
        if (meetingDetails) {
            let editedEvent = (this.$data.editedEvent as Net.MeetingAndInvitees)
            editedEvent.invitees = meetingDetails.invitees;
            editedEvent.participants = meetingDetails.participants;
            DATA.checkMeetingUsers(editedEvent);

            if ((mutation.type === 'setMeeting')
                && ((mutation.payload as Net.MeetingAndInvitees).id == editedEvent.id))
            {
              // set selected invitees
              this.$data.selectedInvitees = [];
              editedEvent.invitees.forEach((i:DB.Meetinginvitee) => {
                                              this.$data.selectedInvitees.push(new KeyedInvitee(i));
                                          });
            }

            this.$data.editedEvent = editedEvent;
        }
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    this.$data.newOrEdit = (meetingId:number) => {
        if (meetingId <= 0) {
            // New meeting
            let _this = this
            // REST.logDebug("newEvent", this.$data.editedEvent, "viewDay:", this.$props.viewDay)
            this.$data.eventDocuments = new Array<DB.Document>();
            this.$data.eventTasks = [];
            this.$data.editedEvent = new Net.MeetingAndInvitees(new DB.Meeting());

            let initDate = new Date(Date.now());
            if (this.$props.viewDay.length != "") {
                initDate = new Date(this.$props.viewDay);
            }
            this.$data.editedEvent.start_time = initDate;
            this.$data.editedEvent.end_time = initDate;
            this.$data.editedEvent.type_id = DATA.getNomenCodeId(store.getters.nomenclator.meetingTypes,
                                                                this.$props.newEventType)
            this.$data.editedEvent.recurrence_id = DATA.recurrenceOnceTypeId();
            this.$data.selectedInvitees = [];
            if (this.$props.newEventType == Net.MeetType_GroupEvent) {
                const { topicsCombo, topicsComboSelection } = buildTopicComboContent(
                                    Array.from(store.getters.getTopics.values()),
                                    0,
                                    this.$props.viewDay)
                this.$data.topicsCombo = topicsCombo;
                this.$data.topicsComboSelection = topicsComboSelection;
                
                this.$data.editedEvent.max_child_cnt = 10;
                this.$data.editedEvent.monthly_topic_id = this.$data.topicsComboSelection.value;
            } else {
                this.$data.editedEvent.monthly_topic_id = 0;
                // creator is invited by default
                let logedInUser = new DB.Meetinginvitee();
                logedInUser.is_member_group = false;
                logedInUser.member_id = REST.userId();
                this.$data.selectedInvitees.push(new KeyedInvitee(logedInUser));
            }
            this.$data.uploadDocTypes = [];         // Documents can be uploaded on exiting meetings
            this.$data.uploadDocTypeComboSel = null;

            this.$data.editEventType = DATA.getNomenIdCode(store.getters.nomenclator.meetingTypes,
                                                            this.$data.editedEvent.type_id)
            this.$data.editEventRecurrence = DATA.getNomenIdCode(store.getters.nomenclator.meetingRecurrences,
                                                            this.$data.editedEvent.recurrence_id)
            this.$data.usersAndGroups = DATA.getMeetingPossibleInvitees(this.$data.editedEvent);
        } else {
            // Edit existing meeting
            store.dispatch('updateMeeting', -1*meetingId);  // Force meeting update
            let editEventRef = store.getters.getMeeting(meetingId) as Net.MeetingAndInvitees;
            let editedEvent = new Net.MeetingAndInvitees(new DB.Meeting());
            if (editEventRef) {
                editedEvent = new Net.MeetingAndInvitees(editEventRef);

                // Shellow copy invities/participants and docs
                editedEvent.invitees.forEach(i => { editEventRef.invitees.push(i); });
                editedEvent.participants.forEach(p => { editEventRef.participants.push(p); });
                editedEvent.docs.forEach(d => { editEventRef.docs.push(d); });
            }
            editedEvent.start_time = new Date(editedEvent.start_time);
            editedEvent.end_time = new Date(editedEvent.end_time);
            this.$data.editEventType = DATA.getNomenIdCode(store.getters.nomenclator.meetingTypes,
                                                        editedEvent.type_id)
            this.$data.editEventRecurrence = DATA.getNomenIdCode(store.getters.nomenclator.meetingRecurrences,
                                                        editedEvent.recurrence_id)

            DATA.checkMeetingUsers(editedEvent);
            this.$data.usersAndGroups = DATA.getMeetingPossibleInvitees(editedEvent);

            if (this.$data.editEventType == Net.MeetType_GroupEvent) {
                const { topicsCombo, topicsComboSelection } = buildTopicComboContent(
                                    Array.from(store.getters.getTopics.values()),
                                    editedEvent.monthly_topic_id,
                                    editedEvent.start_time.toLocaleTimeString())
                this.$data.topicsCombo = topicsCombo;
                this.$data.topicsComboSelection = topicsComboSelection;
            }

            // set selected invitees
            this.$data.selectedInvitees = [];
            editedEvent.invitees.forEach((i:DB.Meetinginvitee) => {
                                        this.$data.selectedInvitees.push(new KeyedInvitee(i));
                                    });

            // Uploadable document types
            this.$data.uploadDocTypes = [];
            this.$data.uploadDocTypeComboSel = null;
            if (REST.isKid()) {
                this.$data.uploadDocTypes.push({text: this.$t('doc_type_' + Net.DocType_MeetHomework),
                                                value: Net.DocType_MeetHomework,
                                                disabled: false});
            }
            if (REST.isAmigo()){
                this.$data.uploadDocTypes.push({text: this.$t('doc_type_' + Net.DocType_MeetMinutes),
                                                value: Net.DocType_MeetMinutes,
                                                disabled: false});
            }
            if (REST.isKid() || REST.isAmigo()) {
                this.$data.uploadDocTypes.push({text: this.$t('doc_type_' + Net.DocType_MeetFeedback),
                                                value: Net.DocType_MeetFeedback,
                                                disabled: false})
            }
            if (REST.isAdmin() || REST.isAmigo()) {
                this.$data.uploadDocTypes.push({text: this.$t('doc_type_' + Net.DocType_MeetingAmigo),
                            value: Net.DocType_MeetingAmigo,
                            disabled: false});
            }
            if (REST.isAdmin()
                || (editedEvent.participants.find((m:DB.Meetingparticipant) => m.user_id == REST.userId())))
            {
                this.$data.uploadDocTypes.push({text: this.$t('doc_type_' + Net.DocType_MeetPub),
                                                value: Net.DocType_MeetPub,
                                                disabled: false})
                this.$data.uploadDocTypes.push({text: this.$t('doc_type_' + Net.DocType_MeetPriv),
                                                value: Net.DocType_MeetPriv,
                                                disabled: false})
            }
            if (this.$data.uploadDocTypes.length > 0) {
                this.$data.uploadDocTypeComboSel = this.$data.uploadDocTypes[0];
            }

            // Get list of meeting documents
            this.$data.eventDocuments = new Array<DB.Document>();
            DATA.getMeetingDocuments(editedEvent.id, this.$data.eventDocuments);

            this.$data.eventTasks = DATA.getMeetingTasks(editedEvent.id);

            this.$data.editedEvent = editedEvent;
        }
    }

    store.dispatch('updateUsers');
    store.dispatch('updateTopics');
    store.dispatch('updateMeetings');
    if (REST.isAdmin()) {
      store.dispatch('updateTasks');
    }

    this.$data.newOrEdit(this.$props.meetingId);

    // REST.logDebug("MEETINGS:beforeMount", this.$props.meetingId);
  }
})
export default class MeetingEdit extends Vue {}
