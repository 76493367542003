


















































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { REST } from '../_util/rest_call'
import { DATA, KeyedInvitee, ChatRoomSelectFn } from '../_util/data'
import Net from '../../../common/net_interface'
import DB from '../../../common/db_struct';

@Component({
  name: 'FilloutReplyShow',
  props: {
    formId: Number,
    value: String,
    showPrivate: Boolean,
    showConcise: Boolean
  },
  watch: {
    formId: function(newVal, oldVal) {
         this.$data.initializeShowForm(newVal);
    },
    value: function(newVal, oldVal) {
        this.$data.filloutValues = DATA.getMapFromString(newVal);
    }
  },
  data () {
        return {
            unsubscribe: null,
            filloutItems: new Array<DB.Filloutitem>(),
            filloutValues: new Map<string, string>(),
        }
  },
  methods: {
    getItemValue(item:DB.Filloutitem) : String {
        let filloutValues = this.$data.filloutValues as Map<string, string>;
        if (filloutValues.has(item.name)) {
            let strVals = filloutValues.get(item.name) || "";
            if (strVals.includes("\t")) {
                if (this.$props.showConcise) {
                    return "<" + strVals.replace(/\t/g, "> <") + ">";
                }
                else {
                    return strVals.replace(/\t/g, "\n", );
                }
            } else {
                return strVals;
            }
        }
        else {
            return ""
        }
    },
    showItem(item:DB.Filloutitem) : Boolean {
        let showAll = this.$props.showPrivate ? true : false
        return showAll || item.non_public == false;
    },
  },
  created() {
    REST.logDebug("FilloutReplyShow: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'mutateQuestionerArray')
      {
        (mutation.payload as Array<Net.FillOutForm>).forEach(f => {
            if (f.id == this.$props.formId) {
                REST.logDebug("SUBSCRIBE: ", mutation.type, " FilloutReplyShow:", f);
                this.$data.initializeShowForm(this.$props.formId);
            }
        });
      }
      else {
          REST.logDebug("🚀 ~ file: FilloutReplyShow.vue ~ line 99 ~ this.$data.unsubscribe=store.subscribe ~ mutation ", mutation,
                      " ~ state ", state)          
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    store.dispatch('updateQuestioners');

    this.$data.initializeShowForm = (formId:number) => {
        this.$data.filloutItems = new Array<DB.Filloutitem>();
        if (formId > 0) {
            let questioner = store.getters.getQuestioner(formId) as Net.FillOutForm;
            if (questioner) {
                let items = questioner.items;
                items.sort((a, b) => a.item_index - b.item_index);
                this.$data.filloutItems = items;
            }
        }
        else {
            console.error("formId is zero")
        }
    }
    this.$data.initializeShowForm(this.$props.formId);
    this.$data.filloutValues = DATA.getMapFromString(this.$props.value);

    REST.logDebug("FilloutReplyShow:beforeMount", this.$props.formId);
  }
})
export default class FilloutReplyShow extends Vue {}
