







































































































































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { DATA } from '../_util/data'
import { KeyedInvitee } from '../_util/data'
import { REST } from '../_util/rest_call'
import { GlobalSnackBarFn } from '../_util/rest_call'
import DB from '../../../common/db_struct'
import Net from '../../../common/net_interface'
import Invitees from './Invitees.vue'

@Component({
  name: 'Groups',
  components: {
    Invitees
  },
  data () {
        return {
            dialog: false,
            unsubscribe: null,
            search: '',
            selectedMember: [],
            groups: new Array<Net.UserGroupAndMembers>(),
            members: new Array<DB.Meetinginvitee>(),
            editedItem: new Net.UpdateGroup(new DB.Usergroup()),
            headers: [
                { text: this.$t('groups.name'), align: 'start', sortable: true, value: 'name' },
                // { text: this.$t('groups.creator'), value: 'creator_id' },
                { text: this.$t('groups.updater'), value: 'updater_id' },
                // { text: this.$t('groups.create_time'), value: 'create_time' },
                { text: this.$t('groups.update_time'), value: 'update_time' },
                { text: this.$t('groups.is_amigo_only'), value: 'is_amigo_only'},
                { text: this.$t('groups.is_dynamic'), value: 'is_dynamic'},
                { text: this.$t('groups.is_public'), value: 'is_public'},
                { text: this.$t('groups.is_active'), value: 'is_active' },
            ],
            kidDynaGroup: false,
            selProfileField: "",
            profileFields: [],
            profileFieldType: "",
            selProfileFieldValue: "",
            profileFieldValues: [],
        }
  },
  watch: {
    kidDynaGroup: function(newVal, oldVal) {
      this.$data.profileFields = DATA.getProfileFormFields(newVal);
      this.$data.selProfileField = this.$data.profileFields[0]
      let dynaSetup = Net.dynaGroupConditionSplit(this.$data.editedItem.dynamic_condition);
      if (dynaSetup.field.length > 0) {
        this.$data.profileFields.forEach((v:any) => {
          if (v.value == dynaSetup.field) {
            this.$data.selProfileField = v
          }
        })
      }
    },
    selProfileField: function(newVal, oldVal) {
      REST.logDebug("selProfileField", newVal)
      this.$data.profileFieldType = newVal.type;
      this.$data.selProfileFieldValue = "";
      if (newVal.type == "LIST") {
        this.$data.profileFieldValues = newVal.values.split("\n");
        this.$data.selProfileFieldValue = this.$data.profileFieldValues[0];
      } else {
        this.$data.profileFieldValues = []
      }
      let dynaSetup = Net.dynaGroupConditionSplit(this.$data.editedItem.dynamic_condition);
      REST.logDebug("dynaSetup", dynaSetup)
      if (dynaSetup.field == newVal.value) {
        this.$data.selProfileFieldValue = dynaSetup.value;
      }
    },
  },
  computed: {
    dateToStr() { 
      return DATA.dateToStr;
    },
    userName () { 
      return DATA.userName;
    },
    isReadonlyGroup() {
      let rv = DATA.isReadonlyGroup(this.$data.editedItem);
      // REST.logDebug("isReadonlyGroup", this.$data.editedItem.name, "->", rv);
      return rv
    },
  },
  methods: {
    isWSConnected() {
        return DATA.isWsConnected();
    },
    close () {
        this.$data.dialog = false;
    },
    save () {
        REST.logDebug("Save", this.$data.editedItem)
        
        let body:any = null;
        let editedGroup = this.$data.editedItem as Net.UpdateGroup;
        // set members
        let method = "PUT"
        let url = "/users/groups"

        if (editedGroup.is_dynamic) {
          if (this.$data.kidDynaGroup) {
            editedGroup.dynamic_condition = Net.KidDynamicGroupPrefix;
          } else {
            editedGroup.dynamic_condition = Net.AmigoDynamicGroupPrefix;
          }
          editedGroup.dynamic_condition += this.$data.selProfileField.value;
          editedGroup.dynamic_condition += "=" + this.$data.selProfileFieldValue;
        }

        if (editedGroup.id > 0) {
            let origGroup = DATA.getGroup(editedGroup.id) as Net.UserGroupAndMembers;
            editedGroup.new_members = [];
            editedGroup.del_members = [];
            this.$data.selectedMember.forEach((i:KeyedInvitee) => {
              let exist = origGroup.members.find(ei => (ei.user_id == i.data.member_id));
              if (!exist) {
                editedGroup.new_members.push(i.data.member_id);
              }
            });
            origGroup.members.forEach(ei => {
              let exist = this.$data.selectedMember.find((i:KeyedInvitee) => (ei.user_id == i.data.member_id));
              if (!exist) {
                editedGroup.del_members.push(ei.user_id);
              }
            })
            body = editedGroup;
            url += "/" + editedGroup.id.toString();
        } else {
            // New Group
            body = new Net.CreateGroup(editedGroup);
            this.$data.selectedMember.forEach((i:KeyedInvitee) => {
              body.members.push(i.data.member_id);
            });
            method = "POST"
        }

        REST.call(method, url,
                  body,
                  (r => { REST.logDebug("SaveGroup:", r);
                      store.dispatch("updateGroup", -r.detail.id);
                      this.$data.dialog = false;
                      return r.detail;
                  }),
                  (async (r) => { 
                    GlobalSnackBarFn(r.toString());
                    REST.logError(" SaveGroup:", r);
                  }));
    },
    isAmigoOnlyChanged() {
      this.$data.members = DATA.getGroupPossibleMembers(this.$data.editedItem.is_amigo_only);
    },
    newItem() {
        this.$data.editedItem = new Net.UpdateGroup(new DB.Usergroup());
        this.$data.editedItem.is_active = true;
        this.$data.selectedMember = [];
        this.$data.members = DATA.getGroupPossibleMembers(this.$data.editedItem.is_amigo_only);

        this.$data.kidDynaGroup = false;
        this.$data.profileFields = DATA.getProfileFormFields(false);
        this.$data.selProfileField = this.$data.profileFields[0]

        this.$data.dialog = true;
    },
    editItem(item: Net.UserGroupAndMembers) {
        this.$data.editedItem = new Net.UpdateGroup(item);
        this.$data.members = DATA.getGroupPossibleMembers(item.is_amigo_only);
        let selectedMember = new Array<KeyedInvitee>();
        item.members.forEach(m => {
                              let sm = new DB.Meetinginvitee();
                              sm.is_deleted = false;
                              sm.is_member_group = false;
                              sm.member_id = m.user_id;
                              selectedMember.push(new KeyedInvitee(sm));
                            });
        this.$data.selectedMember = selectedMember;

        let dynaSetup = Net.dynaGroupConditionSplit(this.$data.editedItem.dynamic_condition);
        this.$data.kidDynaGroup = dynaSetup.kid;
        this.$data.profileFields = DATA.getProfileFormFields(dynaSetup.kid);
        this.$data.selProfileField = this.$data.profileFields[0];
        if (this.$data.editedItem.is_dynamic) {
          this.$data.profileFields.forEach((v:any) => {
            if (v.value == dynaSetup.field) {
              this.$data.selProfileField = v
            }
          })
          this.$data.profileFieldValues = dynaSetup.value;
        }

        this.$data.dialog = true;
    },
  },
  created() {
    REST.logDebug("USERGROUPS: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'mutateGroupArray')
      {
        //this.$data.groups = Array.from(store.getters.getNonAdminGroups.values());
        this.$data.groups = Array.from(store.getters.getGroups.values());
        REST.logDebug("SUBSCRIBE-GROUPS: ", mutation.type, " UserCount:", this.$data.groups);
      }

      if (this.$data.dialog
        && ((mutation.type === 'setUser')
            || (mutation.type === 'mutateUserArray')))
      {
        this.$data.members = DATA.getGroupPossibleMembers(this.$data.editedItem.is_amigo_only);
        REST.logDebug("SUBSCRIBE-GROUPS: ", mutation.type);
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    store.dispatch('updateGroups');
    //this.$data.groups = Array.from(store.getters.getNonAdminGroups.values());
    this.$data.groups = Array.from(store.getters.getGroups.values());
    REST.logDebug("USERGROUPS:beforeMount", this.$data.groups);
  }
})
export default class Groups extends Vue {}
  
