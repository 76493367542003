






































































































































































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { DATA } from '../_util/data'
import { REST } from '../_util/rest_call'
import DB from '../../../common/db_struct'
import Net from '../../../common/net_interface'
import FilloutReplyEdit from './FilloutReplyEdit.vue'

@Component({
    name: 'UserProfile',
    components: {
      FilloutReplyEdit,
    },
    data() {
        return {
            tab: 'tab-base-data',
            editedItem: new Net.UpdateUser,
            isSelectingAvatar: false,
            selectedAvatarFile: null,
            password: "",
            show_password: false,
            edited_dob: "",
            dob_menu: false,
            questionerCompleted: true,
            modifying: false
        }
    },

    // Note: currentUserId is REST.userId()
    computed: {
        submitDisabled() : boolean {
          const baseDataComplete = this.$data.editedItem.given_name.trim().length != 0 &&
            this.$data.editedItem.family_name.trim().length != 0 &&
            this.$data.editedItem.email.trim().length != 0
          if (this.$data.tab == 'tab-base-data') {
            return !DATA.isWsConnected() || !baseDataComplete;
          }
          else{
            return !DATA.isWsConnected() || !baseDataComplete || !this.$data.questionerCompleted;
          }
        },

        // TODO get these rules from a common validator, these has been copied here from Register.vue
        userNameRules() {
              return [
                (v: string) => !!v || this.$t("register.username_is_required"),
                // (v: string) =>
                //   (v && v.length <= 10) || "Username must be less than 10 characters",
              ];
            },

        emailRules() {
            // TODO get this from a common place, reuse Register
              return [
                (v: string) => !!v || this.$t("register.email_is_required"),
                (v: string) =>
                  /.+@.+\..+/.test(v) || this.$t("register.valid_email_required"),
              ];
            },

        passwordAgainRule() {
          return [
            (v: string) =>
              v == this.$data.password ||
              this.$t("register.passwords_should_match"),
          ];
        },        
    
    },

  watch: {
    dob_menu (val) {
      val && setTimeout(() => ((this.$refs.picker as any).activePicker = "YEAR"))
    },
  },

  methods: {
    getFormId() {
      if ((this.$data.editedItem as Net.UpdateUser).is_amigo) {
        return Net.AmigoProfileFormId;
      }
      if ((this.$data.editedItem as Net.UpdateUser).is_kid) {
        return Net.KidProfileFormId;
      }
      return 0;
    },
    onUploadClick() {
      REST.logDebug("onUploadClick, editedItem=", this.$data.editedItem);
      this.$data.isSelectingAvatar = true
      window.addEventListener('focus',
                              () => {
                                   this.$data.isSelectingAvatar = false
                              }, { once: true });
      (this.$refs.uploader as HTMLInputElement).click();
    },
    onFileChanged(e) {
      this.$data.selectedAvatarFile = e.target.files[0]
      const uploadUrl = "/docs/" + Net.DocType_Public + "/";
      const userAvatarPublicDocType = "11";
      REST.call("UPLOAD", uploadUrl + userAvatarPublicDocType,
                this.$data.selectedAvatarFile,
                (r => { 
                    REST.logDebug("Uploaded: ", r);
                    const doc = r.detail as DB.Document;
                    // TODO this should be done on the server side at the same time when we upload
                    this.$data.editedItem.avatar_doc_id = doc.id;
                    // TODO this does not work this.$method.save();
                    REST.call("PUT", "/users/" + REST.userId().toString(),
                              this.$data.editedItem,
                            (r2 => { REST.logDebug("SaveUserProfileAvatar:", r2);
                                store.dispatch("setUser", r2.detail);
                                return r2.detail;
                            }),
                            (async (r2) => { 
                                REST.logError(" SaveUserProfileAvatar:", r2);
                            }));
                   return r;}),
                (async (r) => { 
                    REST.logError(" uploadAvatar:", r);
                }));
    },
    isFileSelected() {
      return this.$data.selectedAvatarFile != null;
    },
    getUserAvatarLink(item: number) {
      return DATA.getUserAvatarLink(item);
    },
    setDateOfBirth() {
      this.$data.dob_menu = false;
      this.$data.editedItem.date_of_birth = new Date(this.$data.edited_dob);
      this.$data.edited_dob = DATA.dateToStrIso(this.$data.editedItem.date_of_birth);
    },
    save() {
        // Edited item
        REST.logDebug("UserProfile/save", this.$data.editedItem)
        this.$data.editedItem.password = this.$data.password;
        REST.call("PUT", "/users/" + REST.userId().toString(),
                  this.$data.editedItem,
                (r => { REST.logDebug("SaveUserProfile:", r);
                    store.dispatch("setUser", r.detail);
                    return r.detail;
                }),
                (async (r) => { 
                    REST.logError(" SaveUserProfile:", r);
                }));
        this.$data.modifying = false
    },
    cancel() {
        this.$data.modifying = false
        let userData = DATA.getUser(REST.userId());
        this.$data.editedItem = new Net.UpdateUser(userData);
        this.$data.edited_dob = DATA.dateToStrIso(userData.date_of_birth)
    }
  },

  created() {
      REST.logDebug("UserProfile/created");
      this.$data.unsubscribe = store.subscribe((mutation, state) => {
          if (mutation.type === 'setUser'){
              const userData = DATA.getUser(REST.userId());
              this.$data.editedItem = new Net.UpdateUser(userData);
              this.$data.edited_dob = DATA.dateToStrIso(userData.date_of_birth)
          }
          else{
              REST.logDebug("UserProfile: not interested in: ", mutation.type, " data:", this.$data);
          }
      });
  },
  beforeDestroy() {
      REST.logDebug("UserProfile/beforeDestroy");
      this.$data.unsubscribe();
  },
  beforeMount() {
      // store.dispatch('');
      REST.logDebug("UserProfile/beforeMount");
      let userData = DATA.getUser(REST.userId());
      this.$data.editedItem = new Net.UpdateUser(userData);
      this.$data.edited_dob = DATA.dateToStrIso(userData.date_of_birth)
      REST.logDebug("UserProfile:beforeMount", this.$data);
  }
})

export default class UserProfile extends Vue {}
