







































































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { DATA } from '../_util/data'
import { REST } from '../_util/rest_call'
import DB from '../../../common/db_struct'
import Net from '../../../common/net_interface'
import UploadReplyEdit from './UploadReplyEdit.vue'
import FilloutReplyEdit from './FilloutReplyEdit.vue'

function saveTaskReply(taskId:number, reply:string, onSuccess:Function)
{
    let payload = new DB.Taskreply();
    payload.task_id = taskId;
    payload.reply = String(reply);

    REST.call("POST", "/tasks/reply",
            payload,
            (r => { REST.logDebug("ReplyTask:", r);
                onSuccess((r as DB.Taskreply).id);
                return r.detail;
            }),
            (async (r) => { 
                REST.logError(" ReplyTask:", r);
            }));
}

@Component({
  name: 'TaskReplyEdit',
  components: {
    UploadReplyEdit,
    FilloutReplyEdit,
  },
  props: {
    taskId:Number,
    closeDialogFn:Function
  },
  watch: {
    taskId: function(newVal, oldVal) {
        this.$data.newOrEdit(newVal);
    }
  },
  data () {
        return {
            reply: '',
            replyId: 0,
            editTaskType: "ACK",
            newOrEdit: null,
            editedItem: new Net.TaskAndAssignee(new DB.Task()),
        }
  },
  computed: {
    dateToStr() { 
      return DATA.dateToStr;
    }
  },
  methods: {
    canSaveReply() {
        if ((this.$data.editTaskType == Net.TaskReplyType_FillOut)
            && !(DATA.validateQuestioner(Number((this.$data.editedItem as Net.TaskAndAssignee).reply_options),
                                        this.$data.reply)))
        {
            return false;
        } else if (this.$data.editTaskType == Net.TaskReplyType_Upload)
        {
            let replyDocuments = new Array<DB.Document>();
            DATA.getTaskReplyDocuments(this.$props.taskId, replyDocuments);
            if (replyDocuments.length < 1) {
                // cannot save if no file uploaded
                return false;
            }
        } else if (((this.$data.editTaskType == Net.TaskReplyType_YesNo)
                    || (this.$data.editTaskType == Net.TaskReplyType_Option)
                    || (this.$data.editTaskType == Net.TaskReplyType_Reply))
                && (this.$data.reply.length == 0))
        {
            return false;
        }
        
        return DATA.isWsConnected();
    },
    getTaskId() {
        if (this.$data.editTaskType == Net.TaskReplyType_FillOut) {
            return Number((this.$data.editedItem as Net.TaskAndAssignee).reply_options);
        }
        return 0;
    },
    replyOptions() {
        let rv = new Array<string>();
        if (this.$data.editTaskType == Net.TaskReplyType_YesNo) {
            rv.push(this.$t('todos.reply_yes').toString());
            rv.push(this.$t('todos.reply_no').toString());
        } else if (this.$data.editTaskType == Net.TaskReplyType_Option) {
            rv = (this.$data.editedItem as Net.TaskAndAssignee).reply_options.split("\n");
        } else {
            REST.logDebug("Unknown TASK type:", this.$data.editTaskType)
        }
        return rv;
    },
    activeTask(item:Net.TaskAndAssignee) {
        return item.is_active && !item.is_cancelled;
    },
    close () {
        this.$props.closeDialogFn();
    },
    fileUploaderFn(uploadFiles:[]) {
        let uploadCallBackFn = async (taskReplyId:number) => {
                REST.logDebug("fileUploaderFn", uploadFiles);
                let uploadUrl = "/docs/" + Net.DocType_TaskReply + "/";
                uploadUrl += taskReplyId
                for (let file of uploadFiles) {
                    REST.call("UPLOAD", uploadUrl,
                            file,
                            (r => { 
                                REST.logDebug("Uploaded: ", r);
                                return r;
                            }),
                            (async (r) => { 
                                REST.logError(" uploadFile:", r);
                            }));
                }
                this.$data.replyId = -1;
                this.$props.closeDialogFn();
            }

        if (this.$data.replyId > 0) {
            uploadCallBackFn(this.$data.replyId);
        } else {
            let editedItem = this.$data.editedItem as Net.TaskAndAssignee;
            let reply = String(this.$data.reply);
            if (this.$data.editTaskType == Net.TaskReplyType_Ack) {
                reply = Net.TaskReplyType_Ack;
            }
            saveTaskReply(editedItem.id, reply, (taskReplyId:number) => {uploadCallBackFn(taskReplyId);})
        }
    },
    save () {
        REST.logDebug("Save", this.$data.editTaskType, this.$data.reply)
        let editedItem = this.$data.editedItem as Net.TaskAndAssignee;
        let reply = String(this.$data.reply);
        if (this.$data.editTaskType == Net.TaskReplyType_Ack) {
            reply = Net.TaskReplyType_Ack;
        }
        saveTaskReply(editedItem.id, reply, (taskReplyId:number) => {
                                this.$data.replyId = -1;
                                this.$props.closeDialogFn();
                            })
    },
  },
  beforeMount() {
    store.dispatch('updateTask', this.$props.taskId);

    this.$data.newOrEdit = (iId:number) => {
                this.$data.reply = "";
                this.$data.replyId = 0;

                let item = store.getters.getTask(iId) as Net.TaskAndAssignee;
                if (item) {
                    this.$data.editTaskType = DATA.getNomenIdCode(store.getters.nomenclator.taskTypes,
                                                                item.type_id);
                    item.replies.forEach(r => {
                        if (r.user_id == REST.userId()) {
                            this.$data.reply = r.reply;
                            this.$data.replyId = r.id;
                        }
                    })
                    if (this.$data.editTaskType == Net.TaskReplyType_FillOut)
                    {
                        let formId = Number(item.reply_options);
                        if ((REST.isAmigo() && (formId == Net.AmigoProfileFormId))
                            || (REST.isKid() && (formId == Net.KidProfileFormId)))
                        {
                            let userData = DATA.getUser(REST.userId()) as Net.Appuser;
                            this.$data.reply = userData.questioner_json;
                        }
                    }

                    this.$data.editedItem = item;
                    REST.logDebug("editItem", this.$data.editTaskType, this.$data.reply)
                } else {
                    REST.logDebug("Cannot find task with ID", iId);
                }
            };

    this.$data.newOrEdit(this.$props.taskId);
  }
})
export default class TaskReplyEdit extends Vue {}
  
