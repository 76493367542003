























































































































































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { DATA, KeyedInvitee } from '../_util/data'
import { REST } from '../_util/rest_call'
import DB from '../../../common/db_struct'
import Net from '../../../common/net_interface'
import DateTime from './DateTime.vue'
import Invitees from './Invitees.vue'
import FilloutReplyShow from './FilloutReplyShow.vue'

@Component({
  name: 'TaskEdit',
  components: {
    DateTime,
    Invitees,
    FilloutReplyShow
  },
  props: {
    taskId:Number,
    linkMeetingId:Number,
    closeDialogFn:Function,
  },
  watch: {
    taskId: function(newVal, oldVal) {
        this.$data.newOrEdit(newVal);
    }
  },
  data () {
        return {
            tab: '',
            assignee_search: '',
            editTaskType: "ACK",
            unsubscribe: null,
            newOrEdit: null,
            usersAndGroups: new Array<DB.Taskassignee>(),
            selectedAssignees: [],
            editedItem: new Net.TaskAndAssignee(new DB.Task()),
            selectedQuestioner: null,
            filloutForms: [],
            replies_header: [
                { text: this.$t('tasks.reply_name'), align: 'start', sortable: true, value: 'name' },
                { text: this.$t('tasks.reply_text'), value: 'reply' },
                { text: this.$t('tasks.reply_time'), value: 'reply_time' },
                { text: this.$t('tasks.is_amigo'), value: 'is_amigo' },
                { text: this.$t('tasks.is_kid'), value: 'is_kid' },
                { text: this.$t('tasks.is_admin'), value: 'is_admin' }
            ],
            showFilloutDialog: false,
            filloutFormJSON: "",
            filloutFormId: '0',
            allowPrivate: Boolean
        }
  },
  computed: {
    dateToStr() { 
      return DATA.dateToStr;
    },
    userName () { 
      return DATA.userName;
    }
  },
  methods: {
    closeFilloutDialog() {
        this.$data.showFilloutDialog = false;
    },
    showQuestionaire(item: DB.Taskreply, formId: String) {
        REST.logDebug("🚀 ~ file: TaskEdit.vue ~ line 216 ~ showQuestionaire ~ item", item)
        this.$data.filloutFormId=Number(formId)
        this.$data.filloutFormJSON=item.reply;
        this.$data.allowPrivate = (REST.isAdmin() || (item.user_id == REST.userId())) ? true : false
        this.$data.showFilloutDialog = true;
     },        
    getReply(item:DB.Taskreply) {
        if (this.$data.editTaskType == Net.TaskReplyType_FillOut) {
            return "..."
        } else {
            return item.reply;
        }
    },
    disableSaveBtn() {
      return !DATA.isWsConnected() || (this.$data.editedItem.title.length==0);
    },
    downloadReply() {
        REST.downloadTaskReplies(this.$props.taskId);
    },
    save () {
        REST.logDebug("Save", this.$data.editedItem)
        let editedItem = this.$data.editedItem as Net.TaskAndAssignee;
        editedItem.type_id = DATA.getNomenCodeId(store.getters.nomenclator.taskTypes,
                                                this.$data.editTaskType);
        if (this.$data.editTaskType == "FILLOUT") {
            editedItem.reply_options = this.$data.selectedQuestioner.value.toString();
        }

        let body:any = null;
        // set members
        let method = "PUT"
        let url = "/tasks"
        if (editedItem.id > 0) {
            // Edited Task
            let payload = new Net.TaskAmend(editedItem);
            payload.new_assignees = [];
            payload.del_assignees = [];
            // calc new members
            this.$data.selectedAssignees.forEach((i:KeyedInvitee) => {
              let exist = editedItem.assignees.find(
                            ai => ((ai.is_member_group == i.data.is_member_group)
                                    && (ai.member_id == i.data.member_id)));
              if (!exist) {
                let ti = new DB.Taskassignee();
                ti.is_deleted = false;
                ti.is_member_group = i.data.is_member_group;
                ti.member_id = i.data.member_id;
                ti.task_id = editedItem.id;
                payload.new_assignees.push(ti);
              }
            });
            // calc deleted members
            editedItem.assignees.forEach(ai => {
              let exist = this.$data.selectedAssignees.find((i:KeyedInvitee) =>
                                                  ((ai.is_member_group == i.data.is_member_group)
                                                  && (ai.member_id == i.data.member_id)));
              if (!exist) {
                let ti = new DB.Taskassignee();
                ti.is_deleted = false;
                ti.is_member_group = ai.is_member_group;
                ti.member_id = ai.member_id;
                ti.task_id = editedItem.id;
                payload.del_assignees.push(ti);
              }
            })
            body = payload;
            url += "/" + editedItem.id.toString();
            REST.call("PUT", "/tasks/" + editedItem.id.toString(),
                    payload,
                    (r => { REST.logDebug("EditTask:", r);
                        this.$props.closeDialogFn();
                        return r.detail;
                    }),
                    (async (r) => { 
                        REST.logError(" EditTask:", r);
                    }));
        } else {
            // New Task
            method = "POST"
            editedItem.assignees = [];
            this.$data.selectedAssignees.forEach((i:KeyedInvitee) => {
              let exist = editedItem.assignees.find(
                            ai => ((ai.is_member_group == i.data.is_member_group)
                                    && (ai.member_id == i.data.member_id)));
              if (!exist) {
                let ti = new DB.Taskassignee();
                ti.is_deleted = false;
                ti.is_member_group = i.data.is_member_group;
                ti.member_id = i.data.member_id;
                ti.task_id = editedItem.id;
                editedItem.assignees.push(ti);
              }
            });
            body = editedItem;
        }

        // Store change
        REST.call(method, url, body,
                  (r => { REST.logDebug("SaveTask:", r);
                      this.$props.closeDialogFn();
                      return r.detail;
                  }),
                  (async (r) => { 
                      REST.logError(" SaveTask:", r);
                  }));

    },
    getMeetingDetails() {
        let rv = " - ";
        let editedItem = this.$data.editedItem as Net.TaskAndAssignee;
        if (editedItem.meeting_link > 0) {
            rv = ""
            store.dispatch('updateMeeting', Number(editedItem.meeting_link));
            let meeting = store.getters.getMeeting(Number(editedItem.meeting_link)) as Net.MeetingAndInvitees;
            if (meeting) {
                rv += "[" + DATA.dateToStr(meeting.start_time) 
                        + " " +  DATA.dateToTimeStrNoSec(meeting.start_time) + "] "
                rv += DATA.getMeetingTitle(meeting)
            }
        }
        return rv;
    },
    getAssigneeName(userId:number, isGroup:boolean) {
        return DATA.getGroupOrUserName(userId, isGroup);
    },
    isAdminIcon(userId:number, isGroup:boolean) {
        return DATA.getTableIcon(userId, isGroup,
                                (user:Net.Appuser) => user.is_admin);
    },
    isAmigoIcon(userId:number, isGroup:boolean) {
        return DATA.getTableIcon(userId, isGroup,
                                (user:Net.Appuser) => user.is_amigo);
    },
    isKidIcon(userId:number, isGroup:boolean) {
        return DATA.getTableIcon(userId, isGroup,
                                (user:Net.Appuser) => user.is_kid);
    },
    hasRepliedIcon(userId:number, isGroup:boolean)
    {
      return DATA.hasRepliedIcon(userId, isGroup, this.$data.editedItem.replies)
    },
    sortReplies(items:DB.Taskreply[], sortBy: string[], sortDesc: boolean[]) {
      if ((sortBy.length == 0) || (sortDesc.length == 0)) {
        return items;
      }
      let index = sortBy[0];
      let isDesc = sortDesc[0];
      items.sort((a, b) => {
        if (index == "reply") {
          return DATA.strCmp(a.reply, b.reply, isDesc);
        } else if (index == "reply_time") {
          return DATA.strCmp(DATA.dateToStr(a.reply_time), DATA.dateToStr(b.reply_time), isDesc);
        } else if (index == "name") {
          return DATA.strCmp(DATA.getGroupOrUserName(a.user_id, false)
                            , DATA.getGroupOrUserName(b.user_id, false)
                            , isDesc);
        } else if (index == "is_amigo") {
          return DATA.strCmp(DATA.getTableIcon(a.user_id, false, (user:Net.Appuser) => user.is_amigo)
                            , DATA.getTableIcon(b.user_id, false, (user:Net.Appuser) => user.is_amigo)
                            , isDesc);
        } else if (index == "is_kid") {
          return DATA.strCmp(DATA.getTableIcon(a.user_id, false, (user:Net.Appuser) => user.is_kid)
                            , DATA.getTableIcon(b.user_id, false, (user:Net.Appuser) => user.is_kid)
                            , isDesc);
        } else if (index == "is_admin") {
          return DATA.strCmp(DATA.getTableIcon(a.user_id, false, (user:Net.Appuser) => user.is_admin)
                            , DATA.getTableIcon(b.user_id, false, (user:Net.Appuser) => user.is_admin)
                            , isDesc);
        }
        REST.logDebug("sortReplies Unknown index:", index);
        return 1;
      })
      return items;
    },
    forKidsChanged() {
        if (this.$data.editedItem.meeting_link == 0) {
            this.$data.usersAndGroups = DATA.getTaskPossibleAssignees(this.$data.editedItem.for_kids);
            REST.logDebug("KIDS", this.$data.editedItem.for_kids, this.$data.usersAndGroups)
        } else {
            this.$data.usersAndGroups = [];
        }
    }
  },
  created() {
    REST.logDebug("TASKEDIT: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      REST.logDebug("SUBSCRIBE-Meetings: ", mutation.type);
      if (mutation.type === 'mutateQuestionerArray')
      {
        this.$data.filloutForms = DATA.getFilloutFormList(false)
      }
      if ((mutation.type === 'setMeeting')
        && (this.$data.editedItem.meeting_link == (mutation.payload as Net.MeetingAndInvitees).id))
      {
        // If referenced meeting updated then re-render all
        this.$forceUpdate();
      }
      if ((mutation.type === 'mutateTopicArray')
        && (Number(this.$data.editedItem.meeting_link) > 0)
        && (Number(this.$data.editedItem.monthly_topic_id) > 0))
      {
       // If referenced meeting is for monthly topic then re-render all
        this.$forceUpdate();
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    store.dispatch('updateQuestioners');

    this.$data.newOrEdit = (iId:number) => {
                this.$data.filloutForms = DATA.getFilloutFormList(false);
                if (this.$data.filloutForms.length > 0) {
                    this.$data.selectedQuestioner = this.$data.filloutForms[0];
                } else {
                    this.$data.selectedQuestioner = null;
                }

                if (Number(iId) == 0) {
                    // Create new task
                    this.$data.editTaskType = "ACK";
                    this.$data.editedItem = new Net.TaskAndAssignee(new DB.Task());
                    this.$data.editedItem.is_active = true;
                    this.$data.editedItem.for_kids = false;
                    this.$data.editedItem.meeting_link = this.$props.linkMeetingId;
                    this.$data.editedItem.pre_meeting_task = false;
                    this.$data.editedItem.dead_line = new Date();
                    if (Number(this.$props.linkMeetingId) > 0) {
                        store.dispatch('updateMeeting', Number(this.$props.linkMeetingId));
                        // default deadline is meeting start time for PRE events
                        let meeting = store.getters.getMeeting(Number(this.$props.linkMeetingId)) as Net.MeetingAndInvitees;
                        this.$data.editedItem.dead_line = meeting.start_time;
                        this.$data.editedItem.pre_meeting_task = true;
                    }

                    this.$data.selectedAssignees = [];
                } else {
                    // edit existing task
                    let item = store.getters.getTask(iId) as Net.TaskAndAssignee;
                    if (!item) {
                        REST.logDebug("TASK not found:", iId);
                        this.$props.closeDialogFn();
                        return;
                    }

                    this.$data.editedItem = new Net.TaskAndAssignee(item);
                    this.$data.editTaskType = DATA.getNomenIdCode(store.getters.nomenclator.taskTypes,
                                                                this.$data.editedItem.type_id);
                    if (this.$data.editTaskType == "FILLOUT") {
                        this.$data.filloutForms.forEach((e:{ text: string; value: any; disabled: boolean; }) => {
                            if (Number(this.$data.editedItem.reply_options) == Number(e.value)) {
                                this.$data.selectedQuestioner = e;
                            }
                        });
                    }

                    this.$data.editedItem.dead_line = new Date(item.dead_line);
                    this.$data.editedItem.assignees = item.assignees;
                    let selectedAssignees = new Array<KeyedInvitee>();
                    item.assignees.forEach(a => {
                                                let mi = new DB.Meetinginvitee();
                                                mi.is_deleted = a.is_deleted;
                                                mi.is_member_group = a.is_member_group;
                                                mi.member_id = a.member_id;
                                                mi.meeting_id = a.task_id;
                                                selectedAssignees.push(new KeyedInvitee(mi));
                                        })
                    this.$data.selectedAssignees = selectedAssignees;
                    this.$data.editedItem.replies = item.replies;
                }
                if (this.$data.editedItem.meeting_link == 0) {
                    this.$data.usersAndGroups = DATA.getTaskPossibleAssignees(this.$data.editedItem.for_kids);
                } else {
                    this.$data.usersAndGroups = [];
                }
            };

    this.$data.newOrEdit(this.$props.taskId);
  }
})
export default class TaskEdit extends Vue {}
  
