























































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import Chat from '../components/Chat.vue';
import Users from '../components/Users.vue';
import Groups from '../components/Groups.vue';
import Meetings from '../components/Meetings.vue';
import MonthlyTopics from '../components/MonthTopics.vue';
import NewChat from '../components/NewChat.vue';
import Pairing from '../components/Pairing.vue';
import Tasks from '../components/Tasks.vue';
import Questioners from '../components/Questioners.vue';
import Todos from '../components/Todos.vue';
import UserProfile from '../components/UserProfile.vue';
import store from '../store'
import { REST } from '../_util/rest_call'
import { DATA } from '../_util/data'
import Net from '../../../common/net_interface'
import DB from '../../../common/db_struct';

function toggleDropDownMenu(btn : any, div : any) {
  (btn as HTMLAnchorElement).classList.toggle("active");
  let roomDiv = (div as HTMLDivElement)
  if (roomDiv.style.display === "block") {
    roomDiv.style.display = "none";
  } else {
    roomDiv.style.display = "block";
  }
}

@Component({
  name: "Main",
  components: {
    Chat,
    Users,
    Meetings,
    MonthlyTopics,
    NewChat,
    Pairing,
    Tasks,
    Questioners,
    Todos,
    UserProfile,
    Groups
  },
  data() {
      return {
          newChatOpened: false,
          sysRoomsOpened: true,
          adminTasksOpened: true,
          chatRoomOpened: true,
          sideMenuOpen: true,
          roomNameFilter: "",
          selected_component: "",
          selected_chatroom: "",
          component_param: "",
          rooms : new Array<Net.ChatRoomAndMembers>(),
          unsubscribe: null,

          // These should be basically consts after store has been fully loaded
          newsRoomId: 0,
          sysRoomId: 0,
          amigoSysRoomId: 0,
          kidSysRoomId: 0
      }
  },
  mounted() {
      REST.logDebug("MAIN mounted:");
      store.dispatch('updateRooms');
  },
  computed: {
    isGroupChat() {
      return function(room:Net.ChatRoomAndMembers) {
        return room.unique_id.startsWith(Net.GROUP_ROOM_PREFIX);
      }
    },
    isMeetingChat() {
      return function(room:Net.ChatRoomAndMembers) {
        return room.unique_id.startsWith(Net.MEETING_ROOM_PREFIX);
      }
    },
    isSuperUser () {
        return REST.userId() == 1;
    },
    isAdmin () {
        return REST.isAdmin();
    },
    isAmigo () {
        return REST.isAmigo();
    },
    isKid () {
        return REST.isKid();
    },
  },
  watch: {
    selected_component: function(newVal, oldVal) {
      REST.logDebug("WATCH selected_component...", oldVal, "=>", newVal);
      let appTitle = this.$t('app_title');
      let selChatRoomId = -1;
      switch (newVal) {
        case 'CHAT':
          selChatRoomId = Number(this.$data.component_param);
          appTitle = DATA.getRoomTitle(selChatRoomId);
          break;
        case 'USERS':
          appTitle = this.$t('navbar.admin_actions_sub.users');
          break
        case 'GROUPS':
          appTitle = this.$t('navbar.admin_actions_sub.usergroup');
          break
        case 'EVENTS':
          appTitle = this.$t('navbar.admin_actions_sub.events');
          break
        case 'TOPICS':
          appTitle = this.$t('navbar.admin_actions_sub.monthy_topics');
          break
        case 'PAIRING':
          appTitle = this.$t('navbar.admin_actions_sub.pairing');
          break
        case 'TASK':
          appTitle = this.$t('navbar.admin_actions_sub.tasks');
          break
        case 'FILLOUTS':
          appTitle = this.$t('navbar.admin_actions_sub.fillouts');
          break
        case 'TASKREPLY':
          appTitle = this.$t('navbar.admin_actions_sub.task_reply');
          break
        case 'USER_PROFILE':
          appTitle = this.$t('navbar.user_profile');
          break
        case 'STATS':
          appTitle = this.$t('navbar.admin_actions_sub.stats');
          break
        case 'SETTINGS':
          appTitle = this.$t('navbar.admin_actions_sub.settings');
          break
        case 'CALENDAR':
          appTitle = this.$t('navbar.calendar');
          break
      }
      this.$store.dispatch('setAppTitle', appTitle);
      this.$store.dispatch('setSelChatRoomId', selChatRoomId);
    },
    roomNameFilter() {
      this.$data.rooms = DATA.nonSystemRooms(store.getters.getRooms, this.$data.roomNameFilter);
    },
    sideMenuOpen() {
      if (this.$data.sideMenuOpen !== store.getters.sideMenuOpen) {
        store.commit('setSideMenuOpen', this.$data.sideMenuOpen);
      }
    }
  },
  methods: {
    appUserName() { 
      return DATA.userName(REST.userId());
    },
    getUserAvatarLink() {
      return DATA.getUserAvatarLink(REST.isLoggedIn() ? REST.userId() : 0);
    },
    closeSideMenu() {  
        store.commit('setSideMenuOpen', false);
    },
    getRoomTitle(room:Net.ChatRoomAndMembers) {
      return DATA.getRoomTitle(room.id);
    },
    updateEventRangeFn(start, end) {
        REST.logDebug("updateRange[", start.date, " -> ", end.date);
        let rv = DATA.meetingsForRange(store.getters.getMeetings, start, end);
        return rv;
    },
    todoTaskCount() {
      let rv = 0;
      (store.getters.getTasks as Map<number, Net.TaskAndAssignee>).forEach(v => {
        if (v.is_active
           && !v.is_cancelled
           && (DATA.getReplyForTask(v) == "")
           && (DATA.isTaskAssignedToMe(v)))
        {
          rv += 1;
        }
      });
      if (rv > 0) {
        return " (" + rv.toString() + ")";
      }
      return "";
    },
    selectRoom(room_id : any) {
      REST.logDebug("Selected chat room:", String(room_id));
      this.$data.component_param = String(room_id);
      this.$data.selected_chatroom = room_id;
      this.$data.selected_component = "CHAT";
      this.$store.dispatch('setSelChatRoomId', room_id);
      this.$store.dispatch('setAppTitle', DATA.getRoomTitle(room_id));
    },
    isSelectedRoom(room_id : any) {
      return this.$data.selected_component == "CHAT" && this.$data.selected_chatroom == room_id;
    },
    unreadMsgCountForRoomId(roomId:number) {
      let room = store.getters.getRoom(roomId);
      if (room && room.unreadMsgCount > 0) {
        return " - " + room.unreadMsgCount.toString();
      }
      return "";
    },
    unreadMsgCountStr(room:Net.ChatRoomAndMembers) {
      if (room.unreadMsgCount > 0) {
        return " - " + room.unreadMsgCount.toString();
      }
      return "";
    },
    // Toggle sidebar drop-down menu
    addNewRoom() {
      REST.logDebug("Adding new room...");
      this.$data.newChatOpened = true;
    },
    toggleCalendar() {
      toggleDropDownMenu(this.$refs.calendar_btn, this.$refs.calendar_div);
    }
  },
  created() {
    REST.logDebug("MAIN: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      if ((mutation.type === 'mutateRoomArray')
          || (mutation.type === 'deleteRoom')
          || (mutation.type === 'incUnreadMsgCount')
          || (mutation.type === 'clearUnreadMsgCount'))
      {
        this.$data.rooms = DATA.nonSystemRooms(store.getters.getRooms, this.$data.roomNameFilter);
        REST.logDebug("MAIN SUBSCRIBE: ", mutation.type, " roomCount:", this.$data.rooms.length);
      }
      if (mutation.type == 'mutateRoomArray')
      {
        // Note that this is not optimal 
        //  - I only want this recomputed at initial rendering once store received the room list
        let sysRoomName = Net.SYSTEM_ROOM_PREFIX + REST.userId()
        this.$data.sysRoomId = DATA.getUniqueRoomId(store.getters.getRooms, sysRoomName);
        this.$data.newsRoomId = DATA.getUniqueRoomId(store.getters.getRooms, Net.NewsRoom);
        this.$data.amigoSysRoomId = DATA.getUniqueRoomId(store.getters.getRooms, Net.AmigoSysRoom);
        this.$data.kidSysRoomId = DATA.getUniqueRoomId(store.getters.getRooms, Net.KidSysRoom);
        if ((this.$data.selected_component == "") && (this.$data.newsRoomId > 0))
        {
          // Select neewsroom by default
          REST.logDebug("Selected chat room:", String(this.$data.newsRoomId));
          this.$data.component_param = String(this.$data.newsRoomId);
          this.$data.selected_chatroom = this.$data.newsRoomId;
          this.$data.selected_component = "CHAT";
          this.$store.dispatch('setSelChatRoomId', this.$data.newsRoomId);
          this.$store.dispatch('setAppTitle', DATA.getRoomTitle(this.$data.newsRoomId));
        }
        REST.logDebug("MAIN(mutateRoomArray):", this.$data.sysRoomId, this.$data.amigoSysRoomId, this.$data.kidSysRoomId)
      }
      if (mutation.type === 'setSideMenuOpen') {
        this.$data.sideMenuOpen = store.getters.sideMenuOpen;
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
      store.dispatch("getNomenclator");
      store.dispatch('updateRooms');
      DATA.setChatSelectFn((room_id : any) => {
                          REST.logDebug("Selected chat room:", String(room_id));
                          this.$data.component_param = String(room_id);
                          this.$data.selected_chatroom = room_id;
                          this.$data.selected_component = "CHAT";
                          this.$store.dispatch('setSelChatRoomId', room_id);
                          this.$store.dispatch('setAppTitle', DATA.getRoomTitle(room_id));
                        });
      DATA.setTodoSelectFn(() => {
                          REST.logDebug("Selected TODO");
                          this.$data.selected_component = 'TASKREPLY';
                        });
      let sysRoomName = Net.SYSTEM_ROOM_PREFIX + REST.userId()
      this.$data.sysRoomId = DATA.getUniqueRoomId(store.getters.getRooms, sysRoomName);
      this.$data.newsRoomId = DATA.getUniqueRoomId(store.getters.getRooms, Net.NewsRoom);
      this.$data.amigoSysRoomId = DATA.getUniqueRoomId(store.getters.getRooms, Net.AmigoSysRoom);
      this.$data.kidSysRoomId = DATA.getUniqueRoomId(store.getters.getRooms, Net.KidSysRoom);
      this.$data.rooms = DATA.nonSystemRooms(store.getters.getRooms, this.$data.roomNameFilter);
      if ((this.$data.selected_component == "") && (this.$data.newsRoomId > 0))
      {
        // Select neewsroom by default
        REST.logDebug("Selected chat room:", String(this.$data.newsRoomId));
        this.$data.component_param = String(this.$data.newsRoomId);
        this.$data.selected_chatroom = this.$data.newsRoomId;
        this.$data.selected_component = "CHAT";
        this.$store.dispatch('setSelChatRoomId', this.$data.newsRoomId);
        this.$store.dispatch('setAppTitle', DATA.getRoomTitle(this.$data.newsRoomId));
      }
  },
  beforeUpdate() {
      this.$data.sideMenuOpen = store.getters.sideMenuOpen;
  },
})
export default class Main extends Vue {}

