



































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { DATA } from '../_util/data'
import { KeyedInvitee } from '../_util/data'
import { REST } from '../_util/rest_call'
import Invitees from './Invitees.vue'
import DB from '../../../common/db_struct'
import Net from '../../../common/net_interface'

@Component({
  name: 'NewChat',
  components: {
    Invitees
  },
  props: {
    openDialog: Boolean,
  },
  data () {
    return {
        seatch_txt: '',
        possibleInvitees: new Array<{member_id:number, is_member_group:boolean}>(),
        selectedInvitees: [],
        editedRoom: new Net.ChatRoomAndMembers(new DB.Chatroom()),
    }
  },
  methods: {
    isWSConnected() {
      return DATA.isWsConnected();
    },
    createChatRoom () {
        let selectedInvitees = this.$data.selectedInvitees as Array<KeyedInvitee>;
        let members = new Array<DB.Chatroommembers>();
        let m = new DB.Chatroommembers();
        m.is_member_group = false;
        m.member_id = REST.userId();
        members.push(m);
        REST.logDebug("selectedInvitees", selectedInvitees)
        selectedInvitees.forEach(sm => {
          if (sm.data.is_member_group || (sm.data.member_id != REST.userId())) {
            m = new DB.Chatroommembers();
            m.is_member_group = sm.data.is_member_group;
            m.member_id = sm.data.member_id;
            members.push(m);
          }
        })
        let editedRoom = this.$data.editedRoom as Net.ChatRoomAndMembers;
        editedRoom.members = members;
        REST.logDebug("createChatRoom", editedRoom);

        REST.call("POST", "/messages/rooms",
                  editedRoom,
                (r => { REST.logDebug("createChatRoom:", r);
                    this.$data.editedRoom = new Net.ChatRoomAndMembers(new DB.Chatroom());
                    return r.detail;
                }),
                (async (r) => { 
                    REST.logError(" createChatRoom:", r);
                }));

        // close topic dialog
        this.$emit('dialogClosing');
    },
  },
  created() {
    REST.logDebug("MONTHLYTOPIC: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      if ((mutation.type === 'mutateGroupArray')
            || (mutation.type === 'mutatePairArray')
            || (mutation.type === 'setPair')
            || (mutation.type === 'mutateUserArray')
            || (mutation.type === 'setUser'))
      {
        this.$data.possibleInvitees = DATA.getPossibleChatInvitees();
        REST.logDebug("SUBSCRIBE: ", mutation.type, " Topics:", this.$data.topics);
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    store.dispatch('updateUsers');
    store.dispatch('updateGroups');
    store.dispatch('updatePairs');
    this.$data.possibleInvitees = DATA.getPossibleChatInvitees();
    REST.logDebug("NEWCHATROOM:beforeMount", this.$data.topics);
  }
})
export default class NewChat extends Vue {}
  
