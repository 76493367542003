















































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { DATA } from '../_util/data'
import { KeyedInvitee } from '../_util/data'
import { REST } from '../_util/rest_call'
import DB from '../../../common/db_struct'
import Net from '../../../common/net_interface'

@Component({
  name: 'Invitees',
  props: {
    usersAndGroups: Array,
    value: Array,
    readOnly: { type: Boolean, default: false},
    hasRepliedColumn: { type: String, default: ""},
    hasRepliedIcon: Function,
  },
  beforeMount() {
      // REST.logDebug("Invitees: beforeMount date...", this.$props.usersAndGroups);
      let keyedUsedGroup = new Array<any>();
      this.$props.usersAndGroups.forEach((ug : DB.Meetinginvitee) => {
            let m = new KeyedInvitee(ug);
            m.type= this.$t(m.type).toString();
            m.isSelectable= !this.$props.readOnly;
            keyedUsedGroup.push(m);
      });
      this.$data.keyedUserGroup = keyedUsedGroup;
      this.$data.selected = this.$props.value;
  },
  watch: {
    value: function(newVal, oldVal) {
      // REST.logDebug("newValue", newVal)
      this.$data.selected = newVal;
    },
    loginList: function(newVal, oldVal) {
      // REST.logDebug("loginList", newVal)
      let loginListStrArray = (newVal as string).split(" ");
      
      // get IDs for every loginName
      let loginListIdArray = new Array<number>();
      let allUsers = store.getters.getUsers as Map<number, Net.Appuser>;
      loginListStrArray.forEach(loginName => {
        allUsers.forEach(v => {
          if (v.login == loginName) {
            loginListIdArray.push(v.id);
          }
        })
      });
      let changed = false;
      this.$props.usersAndGroups.forEach((ug : DB.Meetinginvitee) => {
        if ((!ug.is_member_group) 
            && (loginListIdArray.findIndex(uId => uId == ug.member_id) != -1))
        {
          if (this.$data.selected.findIndex((ki:KeyedInvitee) => !ki.data.is_member_group && (ki.data.member_id == ug.member_id)) == -1)
          {
            let m = new KeyedInvitee(ug);
            m.type= this.$t(m.type).toString();
            m.isSelectable= true;
            this.$data.selected.push(m);
            changed = true;
          }
        }
      });

      if (changed) {
        // REST.logDebug("loginList Changed", loginListStrArray, loginListIdArray, this.$data.selected)
        this.$emit('input', this.$data.selected)
      }
    },
    readOnly: function(newVal, oldVal) {
      // REST.logDebug("readOnly", newVal)
      this.$data.keyedUserGroup.forEach((ug : KeyedInvitee) => {
            ug.isSelectable= !newVal;
      });
    },
    usersAndGroups: function(newVal, oldVal) {
      if (newVal) {
        let keyedUsedGroup = new Array<any>();
        newVal.forEach((ug : DB.Meetinginvitee) => {
            let m = new KeyedInvitee(ug);
            m.type= this.$t(m.type).toString();
            m.isSelectable= !this.$props.readOnly;
            keyedUsedGroup.push(m);
        });
        this.$data.keyedUserGroup = keyedUsedGroup;
        // REST.logDebug("Invitees-usersAndGroups", keyedUsedGroup)
      }
    }
  },
  data () {
        return {
            search: '',
            loginList: '',
            keyedUserGroup: new Array<KeyedInvitee>(),
            selected: [],
            headers: (this.$props.hasRepliedColumn != "" ?
                            [
                                { text: this.$props.hasRepliedColumn, value: 'replied' },
                                { text: this.$t('invitee_name'), align: 'start', sortable: true, value: 'name' },
                                { text: this.$t('invitee_type'), value: 'type' }
                            ] : [
                                { text: this.$t('invitee_name'), align: 'start', sortable: true, value: 'name' },
                                { text: this.$t('invitee_type'), value: 'type' }
                            ])
        }
  },
  methods: {
    itemSelected(event) {
        // REST.logDebug(event);
    },
    getInviteeName(item:KeyedInvitee) {
        return DATA.getGroupOrUserName(item.data.member_id, item.data.is_member_group);
    },
    getInviteeType(item:KeyedInvitee) {
        return this.$t(DATA.getInviteeType(item.data))
    },
    sortInvitees(items:KeyedInvitee[], sortBy: string[], sortDesc: boolean[]) {
      if ((sortBy.length == 0) || (sortDesc.length == 0)) {
        return items;
      }

      items.sort((a, b) => {
          // Put selected items on to of the list
          let selectedItems = (this.$data.selected as Array<KeyedInvitee>)
          let aSelected = (selectedItems.findIndex(ki => ((ki.data.is_member_group == a.data.is_member_group) 
                                                          && (ki.data.member_id == a.data.member_id))) != -1);
          let bSelected = (selectedItems.findIndex(ki => ((ki.data.is_member_group == b.data.is_member_group) 
                                                          && (ki.data.member_id == b.data.member_id))) != -1);
          if (aSelected != bSelected) {
            return (aSelected ? -1 : 1)
          }

          let rv = 0;
          for (let colIdx=0; (rv == 0) && (colIdx<sortBy.length); colIdx++) {
            let index = sortBy[colIdx];
            let isDesc = sortDesc[colIdx];

            // is both selected or both not then compare values
            if (index == "replied") {
              rv = DATA.strCmp(this.$props.hasRepliedIcon(a.data.member_id, a.data.is_member_group)
                                , this.$props.hasRepliedIcon(b.data.member_id, b.data.is_member_group)
                                , isDesc);
            } else if (index == "name") {
              rv = DATA.strCmp(DATA.getGroupOrUserName(a.data.member_id, a.data.is_member_group)
                                , DATA.getGroupOrUserName(b.data.member_id, b.data.is_member_group)
                                , isDesc);
            } else if (index == "type") {
              rv = DATA.strCmp(DATA.getInviteeType(a.data), DATA.getInviteeType(b.data), isDesc);
            } else {
              REST.logDebug("sortInvitees Unknown index:", index);
            }
          }
          return rv;
        })

      return items;
    },
    filterInvitees(value: any, search: string | null, item:KeyedInvitee) {
      if ((search == null) || (!item.isSelectable)) {
        return item.isSelectable;
      }
      return DATA.filterInvitee(item.data, search);
    }
  },
})
export default class Invitees extends Vue {}
  
