










































































































































































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { DATA } from '../_util/data'
import { REST } from '../_util/rest_call'
import DB from '../../../common/db_struct'
import Net from '../../../common/net_interface'
import DateTime from './DateTime.vue'
import Meetings from './Meetings.vue'

@Component({
  name: 'MonthlyTopics',
  components: {
    DateTime,
    Meetings
  },
  data () {
        return {
            topicDialog: false,
            unsubscribe: null,
            search: '',
            uploadFiles: [],
            uploadDocTypes: [],
            uploadDocTypeComboSel: null,
            topics: new Array<DB.Monthlytopic>(),
            topicDocuments: new Array<DB.Document>(),
            editedTopic: new DB.Monthlytopic(),
            kidsFeedBackForm: null,
            amigoFeedBackForm: null,
            filloutForms: [],
            headers: [
                { text: this.$t('topic_is_active'), align: 'start', value: 'is_active' },
                { text: this.$t('topic_title'), align: 'start', value: 'title' },
                { text: this.$t('topic_updater'), value: 'updater_id' },
                { text: this.$t('topic_start_time'), value: 'start_time' },
                { text: this.$t('topic_end_time'), value: 'end_time' },
            ],
            document_header: [
                { text: this.$t('document_name'), align: 'start', sortable: true, value: 'description' },
                { text: this.$t('document_type'), value: 'type_id' },
                { text: this.$t('document_size'), value: 'file_size' },
                { text: this.$t('document_uploader'), value: 'uploader_id' },
                { text: this.$t('document_upload_time'), value: 'upload_time' },
                { text: this.$t('document_is_deleted'), value: 'is_deleted' },
            ],
        }
  },
  methods: {
    isWSConnected() {
      return DATA.isWsConnected();
    },
    canUploadFile() {
      return (this.$data.editedTopic.id > 0) 
              && (this.$data.uploadFiles.length > 0)
              && (this.$data.uploadDocTypeComboSel != null);
    },
    onUploadFileClick() {
        let uploadFiles = this.$data.uploadFiles;
        REST.logDebug("onUploadFileClick", uploadFiles);
        let uploadUrl = "/docs/" + this.$data.uploadDocTypeComboSel.value + "/";
        uploadUrl += this.$data.editedTopic.id

        uploadFiles.forEach((file:any) => {
            REST.call("UPLOAD", uploadUrl,
                      file,
                      (r => { 
                          REST.logDebug("Uploaded: ", r);
                          this.$data.topicDocuments = new Array<DB.Document>();
                          DATA.getTopicDocuments(this.$data.editedTopic.id, this.$data.topicDocuments);
                          return r;
                      }),
                      (async (r) => { 
                          REST.logError(" uploadFile:", r);
                      }));
        })

        // clear list of uploaded files
        this.$data.uploadFiles = [];
    },

    canDeleteRestore(item:DB.Document) {
      return (REST.isAdmin() || (item.uploader_id == REST.userId()))
    },
    deleteDocument(item:DB.Document) {
        if (confirm(this.$t('confirm_doc_delete') + item.description))
        {
          REST.logDebug("deleteDocument", item);
          let uploadUrl = "/docs/" + item.id;
          REST.call("DELETE", uploadUrl,
                        new Boolean(true),
                        (r => { 
                            REST.logDebug("Deleted: ", r);
                            this.$data.topicDocuments = new Array<DB.Document>();
                            DATA.getTopicDocuments(this.$data.editedTopic.id, this.$data.topicDocuments);
                            return r;
                        }),
                        (async (r) => { 
                            REST.logError(" deleteDocument:", r);
                        }));
        }
    },
    restoreDocument(item:DB.Document) {
        if (confirm(this.$t('confirm_doc_restore') + item.description))
        {
          REST.logDebug("restoreDocument", item);
          let uploadUrl = "/docs/" + item.id;
          REST.call("DELETE", uploadUrl,
                        new Boolean(false),
                        (r => { 
                            REST.logDebug("Restore: ", r);
                            this.$data.topicDocuments = new Array<DB.Document>();
                            DATA.getTopicDocuments(this.$data.editedTopic.id, this.$data.topicDocuments);
                            return r;
                        }),
                        (async (r) => { 
                            REST.logError(" restoreDocument:", r);
                        }));
        }
    },
    newEventType() {
      return Net.MeetType_GroupEvent;
    },
    getEventColor(item: any) {
      let tmpMeeting = new Net.MeetingAndInvitees(new DB.Meeting());
      tmpMeeting.monthly_topic_id = item.id;
      let rv = DATA.getMeetingColor(tmpMeeting);
      return rv;
    },
    downloadDocument (item:DB.Document) {
      REST.downloadDocument(item.id);
    },
    saveTopic () {
        let editedTopic = this.$data.editedTopic as DB.Monthlytopic;
        REST.logDebug("SaveTopic", editedTopic);
        let method = "PUT";
        let url = "/meetings/topics";
        if (editedTopic.id > 0) {
            // Edited topic
            url += "/" + editedTopic.id.toString();
        } else {
            // New topic
            method = "POST";
        }

        REST.logDebug("Topic Forms", this.$data.kidsFeedBackForm, this.$data.amigoFeedBackForm)
        if (this.$data.kidsFeedBackForm != null) {
          editedTopic.kid_fill_form = Number(this.$data.kidsFeedBackForm.value);
        } else {
          editedTopic.kid_fill_form = 0;
        }
        if (this.$data.amigoFeedBackForm != null) {
          editedTopic.amigo_fill_form = Number(this.$data.amigoFeedBackForm.value);
        } else {
          editedTopic.amigo_fill_form = 0;
        }

        REST.call(method, url,
                  editedTopic,
                (r => { REST.logDebug("saveTopic:", r);
                    return r.detail;
                }),
                (async (r) => { 
                    REST.logError(" saveTopic:", r);
                }));

        // close topic dialog
        this.$data.topicDialog = false;
    },
    newTopic() {
        this.$data.uploadFiles = [];
        this.$data.uploadDocTypeComboSel = null;
        this.$data.editedTopic = new DB.Monthlytopic();
        this.$data.editedTopic.is_active = true;
        this.$data.editedTopic.is_active = true;
        this.$data.editedTopic.title = "";
        this.$data.editedTopic.description = "";
        this.$data.editedTopic.start_time = new Date(Date.now());
        this.$data.editedTopic.end_time = new Date(Date.now());
        this.$data.editedTopic.kid_fill_form = this.$data.filloutForms[0].value;
        this.$data.editedTopic.amigo_fill_form = this.$data.filloutForms[0].value;
        this.$data.topicDialog = true;
        this.$data.topicDocuments = new Array<DB.Document>();
        this.$data.kidsFeedBackForm = this.$data.filloutForms[0];
        this.$data.amigoFeedBackForm = this.$data.filloutForms[0];
    },
    editTopic(item: DB.Monthlytopic) {
        this.$data.uploadFiles = [];
        this.$data.uploadDocTypeComboSel = null;
        this.$data.editedTopic = new DB.Monthlytopic(item);
        this.$data.editedTopic.start_time = new Date(this.$data.editedTopic.start_time);
        this.$data.editedTopic.end_time = new Date(this.$data.editedTopic.end_time);

        this.$data.kidsFeedBackForm = this.$data.filloutForms[0];
        this.$data.filloutForms.forEach((element:{ text: string; value: any; disabled: boolean; }) => {
          if (element.value == this.$data.editedTopic.kid_fill_form) {
            this.$data.kidsFeedBackForm = element;
          }
        });
        this.$data.amigoFeedBackForm = this.$data.filloutForms[0];
        this.$data.filloutForms.forEach((element:{ text: string; value: any; disabled: boolean; }) => {
          if (element.value == this.$data.editedTopic.amigo_fill_form) {
            this.$data.amigoFeedBackForm = element;
          }
        });

        REST.logDebug("editTopic", this.$data.editedTopic);
        this.$data.topicDialog = true;

        this.$data.topicDocuments = new Array<DB.Document>();
        DATA.getTopicDocuments(this.$data.editedTopic.id, this.$data.topicDocuments);
    },
    updateRange (start, end) {
        return DATA.meetingsForRange(DATA.getGroupOccupationMeetings(store.getters.getMeetings)
                                    , start, end);
    },
    dateToStr(str) { 
      return DATA.dateToStr(str);
    },
    userName (uid) { 
      return DATA.userName(uid);
    },
    getDocumentType(typeId:number) {
      let meetingTypeCode = DATA.getNomenIdCode(store.getters.nomenclator.documentTypes, typeId);
      let rv = this.$t('doc_type_' + meetingTypeCode);
      return rv;
    },
    getDocumentSize(size:number) {
      return DATA.fileSizeToString(size);
    },
  },
  created() {
    REST.logDebug("MONTHLYTOPIC: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'mutateTopicArray')
      {
        this.$data.topics = Array.from(store.getters.getTopics.values());
        REST.logDebug("SUBSCRIBE: ", mutation.type, " Topics:", this.$data.topics);
      }

      if (mutation.type === 'mutateQuestionerArray')
      {
        this.$data.filloutForms = DATA.getFilloutFormList(true)
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    store.dispatch('updateUsers');
    store.dispatch('updateTopics');
    store.dispatch('updateMeetings');
    store.dispatch('updateQuestioners');

    this.$data.topics = Array.from(store.getters.getTopics.values());
    this.$data.filloutForms = DATA.getFilloutFormList(true);

    this.$data.uploadDocTypes = [];
    this.$data.uploadDocTypes.push({text: this.$t('doc_type_' + Net.DocType_Assignment),
                            value: Net.DocType_Assignment,
                            disabled: false});
    this.$data.uploadDocTypes.push({text: this.$t('doc_type_' + Net.DocType_TopicAmigo),
                            value: Net.DocType_TopicAmigo,
                            disabled: false});

    REST.logDebug("MONTHLYTOPIC:beforeMount", this.$data.topics);
  }
})
export default class MonthlyTopics extends Vue {}
  
