


















































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import Net from '../../../common/net_interface'
import { DATA } from '../_util/data'
import { REST } from '../_util/rest_call'
import DateTime from './DateTime.vue'
import Invitees from './Invitees.vue'
import TaskEdit from './TaskEdit.vue'

@Component({
  name: 'Tasks',
  components: {
    DateTime,
    Invitees,
    TaskEdit
  },
  data () {
        return {
            dialog: false,
            unsubscribe: null,
            search: '',
            tasks: [],
            editTaskId: 0,
            editTaskMeetingId: 0,
            headers: [
                { text: this.$t('tasks.is_active'), value: 'is_active' },
                { text: this.$t('tasks.title'), align: 'start', sortable: true, value: 'title' },
                { text: this.$t('tasks.dead_line'), value: 'dead_line' },
                { text: this.$t('tasks.updater'), value: 'updater_id' },
                { text: this.$t('tasks.update_time'), value: 'update_time' },
            ],
        }
  },
  watch: {
    dialog: function(newVal, oldVal) {
      if (newVal == false) {
        this.$data.editTaskId=-1;
        this.$data.editTaskMeetingId=-1;
      }
    },
  },
  computed: {
    dateToStr() { 
      return DATA.dateToStr;
    },
    userName () { 
      return DATA.userName;
    }
  },
  methods: {
    closeDialog() {
      this.$data.dialog = false;
    },
    newTAsk() {
      this.$data.editTaskId=0;
      this.$data.editTaskMeetingId=0;
      this.$data.dialog=true;
    },
    sortTasks(items:Net.TaskAndAssignee[], sortBy: string[], sortDesc: boolean[]) {
      return DATA.sortTasks(items, sortBy, sortDesc);
    },
  },
  created() {
    REST.logDebug("TASKS: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      if ((mutation.type === 'mutateTaskArray')
            || (mutation.type === 'setTaskReply')
            || (mutation.type === 'setTask')
            || (mutation.type === 'deleteTask'))
      {
        this.$data.tasks = Array.from(store.getters.getTasks.values());
        REST.logDebug("SUBSCRIBE: ", mutation.type, " UserCount:", this.$data.tasks);
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    store.dispatch('updateUsers');
    store.dispatch('updateGroups');
    store.dispatch('updateTasks');
    this.$data.tasks = Array.from(store.getters.getTasks.values());
    REST.logDebug("TASKS:beforeMount", this.$data.tasks);
  }
})
export default class Tasks extends Vue {}
  
