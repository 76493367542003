















































import { Component, Vue } from 'vue-property-decorator';
import store from '../store'
import { DATA } from '../_util/data'
import { REST } from '../_util/rest_call'
import Net from '../../../common/net_interface'
import TaskReplyEdit from './TaskReplyEdit.vue'

@Component({
  name: 'Todos',
  components: {
    TaskReplyEdit
  },
  data () {
        return {
            dialog: false,
            unsubscribe: null,
            editTaskId: 0,
            search: '',
            tasks: new Array<Net.TaskAndAssignee>(),
            headers: [
                { text: this.$t('tasks.is_active'), value: 'is_active' },
                { text: this.$t('tasks.title'), align: 'start', sortable: true, value: 'title' },
                { text: this.$t('tasks.dead_line'), value: 'dead_line' },
                { text: this.$t('tasks.updater'), value: 'updater_id' },
                { text: this.$t('tasks.update_time'), value: 'update_time' },
                { text: this.$t('todos.reply_date'), value: 'reply_date' },
                { text: this.$t('todos.reply'), value: 'reply' },
            ],
        }
  },
  computed: {
    dateToStr() { 
      return DATA.dateToStr;
    },
    userName () { 
      return DATA.userName;
    }
  },
  methods: {
    closeDialog() {
        this.$data.dialog = false;
        this.$data.editTaskId = -1;
    },
    editItem(item:Net.TaskAndAssignee) {
        this.$data.editTaskId = item.id;
        this.$data.dialog=true;
    },
    activeTask(item:Net.TaskAndAssignee) {
        return item.is_active && !item.is_cancelled;
    },
    getReply(item:Net.TaskAndAssignee) {
        return DATA.getReplyForTask(item);
    },
    replyDate(item:Net.TaskAndAssignee) {
        return DATA.getReplyDateForTask(item);
    },
    sortTasks(items:Net.TaskAndAssignee[], sortBy: string[], sortDesc: boolean[]) {
      return DATA.sortTasks(items, sortBy, sortDesc);
    },
  },
  created() {
    REST.logDebug("TASKS: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      if ((mutation.type === 'mutateTaskArray')
            || (mutation.type === 'setTaskReply')
            || (mutation.type === 'setTask')
            || (mutation.type === 'deleteTask'))
      {
        this.$data.tasks = DATA.getAssignedTasks();
        REST.logDebug("SUBSCRIBE: ", mutation.type, " UserCount:", this.$data.tasks);
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    store.dispatch('updateUsers');
    store.dispatch('updateGroups');
    store.dispatch('updateTasks');
    this.$data.tasks = DATA.getAssignedTasks();
    REST.logDebug("TASKS:beforeMount", this.$data.tasks);
  }
})
export default class Todos extends Vue {}
  
